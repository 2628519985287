import React, {useContext, useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import { Scrollbars } from 'rc-scrollbars';
import AuthContext from '../_shared/AuthContext';
import Button from '../_shared/Button';
import Icon from '../_shared/Icon';
import LoaderBig from "../_shared/LoaderBig";
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const DeletePopUp = ({ deleteUser, setDeletePopUp, setPopUp, setPopUpText, setReload }) => {
  const { t } = useTranslation();

  const deleteUserHandler = () => {
    JwtInterceptor
        .delete(`${API_URL}Users/delete/${deleteUser.id}`)
        .then((response) => {
          setDeletePopUp(false);
          setReload(true);
        })
        .catch(function (error) {
          setDeletePopUp(false);
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUp('true');
        });
  }

  const closePopUpHandler = () => {
    setDeletePopUp(false);
  }

  return (
      <div>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-fit p-6 bg-white">
          <p className="text-base">{t('deleteUserQuestion')} <span className="font-nouvelrbold">{deleteUser.name}?</span></p>
          <div className="flex justify-center mt-5">
            <Button anchor={t('yes')} icon="check" onClickFnc={deleteUserHandler} />
            <Button anchor={t('no')} icon="x" onClickFnc={closePopUpHandler} styles="ml-5" />
          </div>
        </div>
      </div>
  );
}

const TeamTableRow = ({ user, role, setDeleteUser, setDeletePopUp, setUsername }) => {
  const deleteHandler = () => {
    setDeleteUser({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`
    });

    setDeletePopUp(true);
  };

  const userHandler = (e, username) => {
    e.preventDefault();
    setUsername(username);
  }

  return (
      <tr>
        <td className="px-2 py-4">
          {role === 'Admin' ?
            <a href="/#" className="underline" onClick={(e) => userHandler(e, user.userName)}>{user.firstName} {user.lastName}</a>
          :
            `${user.firstName} ${user.lastName}`
          }
        </td>
        <td className="px-2 py-4">{user.userName}</td>
        <td className="px-2 py-4">{user.email}</td>
        <td className="px-2 py-4">{user.phoneNumber}</td>
        <td className="px-2 py-4">{user.cardNumber}</td>
        <td className="px-2 py-4">{user.roles[0]}</td>
        <td className="relative py-4">
          <NavLink to={`/add-user/${user.id}`}>
            <Icon id="edit" viewBox="0 0 32 32" width="30" height="30" clsWrap="absolute top-2.5 transform transition duration-y hover:cursor-pointer hover:scale-125 hover:cursor-pointer" />
          </NavLink>
        </td>
        <td className="relative py-4 px-2"><Icon id="delete" viewBox="0 0 24 24" width="30" height="30" clsWrap="absolute top-2.5 right-1 transform transition duration-y hover:cursor-pointer hover:scale-125 hover:cursor-pointer" onClickFnc={deleteHandler} /></td>
      </tr>
  );
}

const TeamTable = () => {
  const { t } = useTranslation();
  const { setAuthToken, setUser } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [deleteUser, setDeleteUser] = useState({});
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';
  const scrollbarHeight = window.innerHeight - 290;

  useEffect(() => {
    const loadUser = () => {
      setLoader(true);

      JwtInterceptor
          .get(`${API_URL}Users/all`)
          .then((response) => {
            setUsers(response.data);
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
          }).finally(() => {
            setReload(false);
            setLoader(false);
          });
    }

    loadUser();

    if (reload) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (username) {
      JwtInterceptor
          .post(`${API_URL}Users/token/${username}`)
          .then((response) => {
            const tempTokens = localStorage.getItem('tokens');
            localStorage.setItem('tempTokens', tempTokens);

            localStorage.setItem('tokens', JSON.stringify(response.data));
            setUser(jwtDecode(response.data.token));
            setAuthToken(JSON.parse(JSON.stringify(response.data)));
            window.location = "/";
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
          }).finally(() => {
        setReload(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  const rows = users.map(user => <TeamTableRow user={user} key={user.id} role={role} setDeleteUser={setDeleteUser} setDeletePopUp={setDeletePopUp} setUsername={setUsername} />);

  return (
      <Scrollbars style={{ maxWidth: '100%', height: scrollbarHeight }} autoHide={false}>
        <table className="w-full text-left">
          <thead className="bg-black font-nouvelrbold text-white text-base">
            <tr>
              <th className="px-2 py-4 align-middle">{t('nameSurname')}</th>
              <th className="px-2 py-4 align-middle">{t('userName')}</th>
              <th className="px-2 py-4 align-middle">{t('email')}</th>
              <th className="px-2 py-4 align-middle">{t('phone')}</th>
              <th className="px-2 py-4 align-middle">{t('cardNumber')}</th>
              <th className="px-2 py-4 align-middle">{t('role')}</th>
              <th className="w-10 px-4 py-4 align-middle"></th>
              <th className="w-10 px-4 py-4 align-middle"></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
        {loader && <LoaderBig />}
        {deletePopUp && <DeletePopUp deleteUser={deleteUser} setDeletePopUp={setDeletePopUp} setPopUp={setPopUp} setPopUpText={setPopUpText} setReload={setReload} />}
        <PopUp open={popUp} text={popUpText} type='error' hideFnc={popUpCloseHandler} />
      </Scrollbars>
  );
}

export default TeamTable;