import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Menu = () => {
  const { t } = useTranslation();
  const active = 'mr-6 text-black font-bold transition ease-in-out duration-300 border-b-4 border-b-brand border-solid pb-2';
  const notActive = 'mr-6 hover:text-black font-bold hover:border-b-4 hover:border-b-brand hover:border-solid hover:pb-2 transition ease-in-out duration-300';
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';
  const {pathname} = useLocation();

  return (
    <nav className="mx-auto flex max-w-screen-lg pb-4 md:pb-6 font-nouvelrbold text-xl md:text-3xl overflow-x-scroll lg:overflow-x-hidden">
        <ul className="flex text-gray whitespace-nowrap">
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive || pathname === '/ended') ? active : notActive}>
              Konkursy
            </NavLink>
          </li>
          {role === 'Admin' &&
            <li>
              <NavLink to="/competition-management" className={({ isActive }) => isActive ? active : notActive}>
                Zarządzanie konkursami
              </NavLink>
            </li>
          }
          <li>
            <NavLink to="/informations" className={({ isActive }) => isActive ? active : notActive}>
              Informacje
            </NavLink>
          </li>
          {(role === 'Admin' || role === 'ServiceChief') &&
            <li>
              <NavLink to="/team" className={({ isActive }) => isActive ? active : notActive}>
                Zespół
              </NavLink>
            </li>
          }
          {role === 'Admin' &&
            <li>
              <NavLink to="/communication" className={({ isActive }) => isActive ? active : notActive}>
                Komunikacja
              </NavLink>
            </li>
          }
        </ul>
    </nav>
  )
}

export default Menu;