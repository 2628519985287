import React, {useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { reverseDate } from '../../commons/functions';
import Button from "../_shared/Button";
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionManagementBoxPastItem = ({ item, setReload }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');

  const settlementHandler = (id) => {
    setLoader(true);

    JwtInterceptor
        .post(`${API_URL}Competitions/manage/edition/${id}/calculatePrizes`)
        .then((response) => {
          setPopUpText(t('prizeSettlementSuccess'));
          setPopUpType('success');
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
        })
        .finally(() => {
          setLoader(false);
          setPopUp(true);
        });
  }

  const popUpCloseHandler = () => {
    setPopUp(false);
    setReload(true);
  }

  return (
      <div className="flex flex-col xs:flex-row xs:justify-between items-center mt-4 text-base">
        <div className="xs:w-8/12">
          <span className="sm:flex sm:justify-between">
            <span className="block sm:w-1/2 font-nouvelrbold">{item.name}</span>
            <span className="block sm:w-1/2">
              <span className="text-nowrap">{reverseDate(item.dateFrom)}</span> -&nbsp;
              <span className="text-nowrap">{reverseDate(item.dateTo)}</span>
            </span>
          </span>
        </div>
        <div  className="xs:w-4/12 mt-5 xs:mt-0">
          <Button anchor={t('settlement')} icon={'prize'} loading={loader} styles="w-fit float-right" onClickFnc={() => settlementHandler(item.id)} />
        </div>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </div>
  );
}

const CompetitionManagementBoxPast = ({ past, setReload }) => {
  const { t } = useTranslation();

  return (
      <div className="pt-6 pb-6 border-solid border-t border-grayBorder">
        <p className="text-xl text-center xs:text-left">{t('stageEnded')}</p>
        {past.map(item => <CompetitionManagementBoxPastItem item={item} key={item.id} setReload={setReload} />)}
      </div>
  );
}

export default CompetitionManagementBoxPast;