import React from 'react';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

const CompetitionSearchMenu = () => {
  const { t } = useTranslation();
  const active = 'mr-6 text-black font-bold transition ease-in-out duration-300 border-b-4 border-b-brand border-solid pb-2';
  const notActive = 'mr-6 hover:text-black font-bold hover:border-b-4 hover:border-b-brand hover:border-solid hover:pb-2 transition ease-in-out duration-300';

  return (
      <div className="pt-2 pb-6 md:pb-8 text-lg md:text-xl">
        <ul className="flex text-gray">
          <li>
            <NavLink to="/" end className={({ isActive }) => isActive ? active : notActive}>
              {t('current')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/ended" end className={({ isActive }) => isActive ? active : notActive}>
              {t('ended')}
            </NavLink>
          </li>
        </ul>
      </div>
  )
}

export default CompetitionSearchMenu;