import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Loader from '../_shared/Loader';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const CommunicationFrom = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [checkboxError, setCheckboxError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [textareaVal, setTextareaVal] = useState(false);

  const onSubmit = async (data) => {
    if (!data.sendToServiceChiefs && !data.sendToServiceAdvisors) {
      setCheckboxError(true);
      return;
    } else {
      setCheckboxError(false);
    }

    if (!loader) {
      setLoader(true);

      JwtInterceptor
          .post(`${API_URL}Notification/send`, data)
          .then((response) => {
            setPopUpText(t('communicationSendSuccess'));
            setPopUpType('success');
            reset();
            setTextareaVal(false);
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          }).finally(() => {
            setLoader(false);
            setPopUp(true);
          });
    }
  };

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  const textareaHandler = (e) => {
    setTextareaVal(e.currentTarget.value);
  }

  return (
      <>
        <form className="flex flex-col mt-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mt-4 float-label-input">
            <input type="text" id="subject" placeholder=" " {...register("subject", { required: true, maxLength: 100, pattern: /^[^\s]+[^^<>&]{2,200}$/i})} maxLength="100" className="block w-full md:w-1/2 py-1.5 outline-none border-b border-solid border-grayBorder" />
            <label htmlFor="subject" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('messageTitle')} *</label>
            {errors.subject && errors.subject.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.subject && errors.subject.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <div className={`relative mt-8 float-label-input ${textareaVal ? 'isValue' : ''}`}>
            <textarea id="message" placeholder=" " {...register("message", { required: true, maxLength: 2000, pattern: /^[^\s]+[^^<>&]{2,2000}$/i})} maxLength="2000" className="block w-full md:w-1/2 h-44 py-1.5 outline-none border-b border-solid border-grayBorder" onChange={e => textareaHandler(e)} />
            <label htmlFor="message" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('yourMessage')} *</label>
            {errors.message && errors.message.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.message && errors.message.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <div className="relative mt-8 float-label-input">
            <input type="text" id="signature" placeholder=" " {...register("signature", { required: true, maxLength: 100, pattern: /^[^\s]+[^^<>&]{2,100}$/i})} maxLength="100" className="block w-full md:w-1/2 py-1.5 outline-none border-b border-solid border-grayBorder" />
            <label htmlFor="signature" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('signature')} *</label>
            {errors.signature && errors.signature.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.signature && errors.signature.type === "pattern" && <span className="text-error">{t('errFieldEmail')}</span>}
          </div>
          <p className="mt-6 mb-4 font-nouvelrbold">{t('messageRecipents')}:</p>
          <div className="flex">
            <fieldset className="flex items-center">
              <input id="sendToServiceChiefs" type="checkbox" {...register("sendToServiceChiefs")} className="h-5 w-5 mr-2 accent-brand " />
              <label htmlFor="sendToServiceChiefs">{t('serviceHeads')}</label>
            </fieldset>
            <fieldset className="flex items-center ml-4">
              <input id="sendToServiceAdvisors" type="checkbox" {...register("sendToServiceAdvisors")} className="h-5 w-5 mr-2 accent-brand" />
              <label htmlFor="sendToServiceAdvisors">{t('serviceAdvisors')}</label>
            </fieldset>
          </div>
          {checkboxError && <span className="pt-2 text-error">{t('errFieldRequired')}</span>}
          <button type="submit" className={`relative w-fit mt-8 p-4 border font-nouvelrbold text-black transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand ${loader ? 'pr-12' : ''}`}>
            {t('send')}
            {loader && <Loader />}
          </button>
        </form>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </>
  );
}

export default CommunicationFrom;