import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../commons/context';
import { useNavigate } from 'react-router-dom';
import AuthContext from './_shared/AuthContext';
import Button from './_shared/Button';
import Icon from './_shared/Icon';
import JwtInterceptor from './_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const TermsPopUp = ({ acceptFnc, rejectFnc }) => {
  const { t } = useTranslation();

  return (
      <>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 max-h-80vh overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 lg:w-1/2 p-6 md:py-8 bg-white text-lg md:text-xl terms">
          <p className="font-nouvelrbold">{t('termsPopUpTextBold')}</p>
          <p dangerouslySetInnerHTML={{__html: t(`termsPopUpText`) }} />
          <div className="md:flex mt-6 text-center">
            <Button anchor={t('termsPopUpAcceptBtn')} styles="text-base leading-4" onClickFnc={acceptFnc} />
            <Button anchor={t('termsPopUpRejectBtn')} styles="mt-6 md:mt-0 md:ml-6 text-base leading-4" onClickFnc={rejectFnc} />
          </div>
        </div>
      </>
  )
}

const Header = ({ setBlurePage }) => {
  const { t } = useTranslation();
  const { acceptTerms, logout, authToken } = useContext(AuthContext);
  const [data, setData] = useContext(Context);
  const [isShowing, setIsShowing] = useState(false);
  const [termsPopUp, setTermsPopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';

  useEffect(() => {
    if (!role) {
      navigate("/login");
    } else {
      if (!termsPopUp) {
        JwtInterceptor
            .get(`${API_URL}Users`)
            .then((response) => {
              setBlurePage(false);
              setData({
                type: 'SET_USER',
                payload: {
                  ...response.data,
                },
              });
            })
            .catch(function (error) {
              if (error.response.status !== 406) {
                logout();
              } else {
                setTermsPopUp(true);
                setBlurePage(true);
              }
            });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsPopUp]);

  useEffect(() => {
    setIsShowing(false);
  }, [location]);

  const profileMenuHandler = (e) => {
    e.preventDefault();
    setIsShowing((isShowing) => !isShowing)
  }

  const popUpAcceptHandler = () => {
    const obj = {
      "termsAccepted": true,
      "token": authToken.token,
      "refreshToken": authToken.refreshToken
    }

    JwtInterceptor
        .post(`${API_URL}Users/acceptTerms`, obj)
        .then((response) => {
          acceptTerms(response.data);
          setTermsPopUp(false);
          setBlurePage(false);
        })
        .catch(function (error) {
          console.log('error', error);
        });
  }

  const popUpRejectHandler = () => {
    logout();
  }

  return (
      <header className="bg-white shadow-custom font-nouvelr">
        <div className="mx-auto flex max-w-screen-lg items-center justify-between py-5 px-5" aria-label="Global">

          <div className="flex items-center">
            <a href="/#" className="text-sm font-semibold leading-6 text-gray-900">
              <Icon id="renaultGroup" viewBox="0 0 70 32" width="70" height="32" />
            </a>
            <span className="mx-4 w-px h-8 bg-black"></span>
            <a href="/#" className="text-sm font-semibold leading-6 text-gray-900">
              <Icon id="rServiceClub" viewBox="0 0 78 30" width="78" height="30" />
            </a>
          </div>

          <div className="flex items-cneter relative">
            <a href="/#" className="flex items-center uppercase hover:underline" onClick={(e) => profileMenuHandler(e)}>
              <span className="hidden md:inline">{`${data.user.firstName} ${data.user.lastName}`}</span>
              <Icon id="userIcon" viewBox="0 0 30 30" width="30" height="30" />
            </a>
            <Link to="/contact" className="flex items-center uppercase ml-3 hover:underline">
              <span className="hidden md:inline">{t('contact')}</span>
              <Icon id="envelope" viewBox="0 0 30 30" width="30" height="30" />
            </Link>
            <Transition
                as={Fragment}
                show={isShowing}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
              <div className="absolute right-5 md:right-24 z-20 flex flex-col mt-10 p-6 bg-white shadow-popover round">
                <Link to="/profile" className="mb-2 text-base hover:underline">{t('myProfile')}</Link>
                <Button anchor={t('logout')} icon="logout" onClickFnc={logout} />
              </div>
            </Transition>
          </div>

        </div>

        {termsPopUp && <TermsPopUp acceptFnc={popUpAcceptHandler} rejectFnc={popUpRejectHandler} />}
      </header>
  )
}

export default Header;