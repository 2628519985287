import React from 'react';
import PropTypes from 'prop-types';

const ProgressBarMini = ({ percent }) => {
  let color = 'bg-progressBarMini0';

  if (percent < 30) {
    color = 'bg-progressBarMini0';
  } else if (percent >= 30 && percent < 60) {
    color = 'bg-progressBarMini30';
  } else if (percent >= 60 && percent < 100) {
    color = 'bg-progressBarMini60';
  } else {
    color = 'bg-progressBarMini100';
  }

  return (
      <div className="w-53px h-4 mx-3 bg-progressBarMiniBg rounded">
        <span style={{'width': percent + '%'}} className={`block w-53px h-4 m-0 rounded max-w-full ${color}`}></span>
      </div>
  );
}

ProgressBarMini.defaultProps = {
  percent: 0,
}

ProgressBarMini.propTypes = {
  percent: PropTypes.number,
}


export default ProgressBarMini;