import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ButtonLink from "./_shared/ButtonLink";

const Page404 = () => {
  return (
      <div>
        <Header />
        <div className="py-20 md:py-60 text-center font-poppins">
          <p className="text-56px font-nouvelrbold">404</p>
          <p className="pt-10 pb-20 text-3xl font-nouvelrbold">Szukana strona nie istnieje</p>
          <ButtonLink anchor="Wróć na stronę główną" href="/" />
        </div>
        <Footer />
      </div>
  )
}

export default Page404;