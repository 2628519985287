import React, {useContext} from 'react';
import AuthContext from './_shared/AuthContext';

const Footer = () => {
  const { setAuthToken, setUser } = useContext(AuthContext);
  const tempTokens = localStorage.getItem('tempTokens');

  const adminReloadHandler = (e, user) => {
    e.preventDefault();

    if (tempTokens) {
      localStorage.setItem('tokens', tempTokens);
      setUser();
      setAuthToken();
      localStorage.removeItem('tempTokens');
      window.location = "/";
    }
  }

  return (
      <footer className="font-nouvelr md:mt-20">
        <div className="bg-beige">
          <div className="mx-auto max-w-screen-lg md:flex md:justify-between p-5 md:px-140px md:py-5 text-white text-left">
            <p>© Renault Group 2023</p>
            <ul className="md:flex">
              <li className="pt-5 md:pt-0">
                <a href="/information-finding" className="hover:border-b hover:border-solid">Klauzula informacyjna</a>
              </li>
              {tempTokens &&
                <li className="pt-5 md:pt-0 md:ml-5">
                  <a href="/#" className="font-nouvelrbold border-b border-solid" onClick={(e) => adminReloadHandler(e)}>Powrót do konta</a>
                </li>
              }
            </ul>
          </div>
        </div>
      </footer>
  )
}

export default Footer;