import React, {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Button from "../_shared/Button";
import CompetitionManagementBoxCurrent from "./CompetitionManagementBoxCurrent";
import CompetitionManagementBoxFuture from "./CompetitionManagementBoxFuture";
import CompetitionManagementBoxPast from "./CompetitionManagementBoxPast";

const CompetitionManagementBox = ({ competition, setReload, images }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stageForSettlement, setStageFormSettlement] = useState([]);

  useEffect(() => {
    const stategforsettlement = competition.editions.past.filter(edition => edition.prizeCalculatedOn === null);
    setStageFormSettlement(stategforsettlement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const competitionHandler = (url) => {
    const newCompetitionData = {
      id: competition.competitionId,
      name: competition.competitionName,
      prizeType: competition.prizeType,
      targetType: competition.targetType
    }

    sessionStorage.setItem('competition', JSON.stringify(newCompetitionData));

    navigate(url);
  }

  return (
      <div className="md:flex md:justify-between my-10">
        <img src={`/images/${images[competition.competitionId]}`} alt={competition.competitionName} className="h-fit w-full md:w-5/12 lg:w-full mb-5 md:mb-0" />
        <div className="w-full md:w-7/12 lg:w-full md:ml-10">

          <h3 className="lg:flex lg:items-start lg:justify-between pb-6">
            <span className="inline-block w-full xs:w-8/12 md:w-full lg:w-8/12 text-center xs:text-left font-nouvelrbold text-3xl md:text-40px">{competition.competitionName}</span>
            <div className="flex flex-col md:items-end md:mt-4 lg:mt-0 text-center xs:text-right">
              <Button anchor={t('addNewStage')} iconLeft="plusThin" styles="mt-5 w-fit inline-block md:mt-0 grow-0" onClickFnc={() => competitionHandler('/competition-management-edit/new')} />
              {competition.competitionId === "videoRepair" &&
                  <Button anchor={t('resultsUpdate')} iconLeft="download" iconStyles="rotate-180" styles="mt-5 w-fit inline-block grow-0" onClickFnc={() => competitionHandler('/competition-management-edit/result-update')} />
              }
            </div>
          </h3>

          {competition.editions.current && <CompetitionManagementBoxCurrent current={competition.editions.current} competitionHandler={competitionHandler} />}
          {competition.editions.future && competition.editions.future.length > 0 && <CompetitionManagementBoxFuture future={competition.editions.future} competitionHandler={competitionHandler} />}
          {stageForSettlement && stageForSettlement.length > 0 && <CompetitionManagementBoxPast past={stageForSettlement} setReload={setReload} />}
        </div>
      </div>
  );
}

export default CompetitionManagementBox
