import React from 'react';
import { useTranslation } from "react-i18next";

const InformationsBanner = () => {
  const { t } = useTranslation();

  return (
      <section className="md:flex md:justify-end md:w-full">
        <img className="mt-2 md:mt-0 md:float-right z-10 md:w-52% md:h-fit order-2 relative" src="/images/mainpage.png" alt="grafika baneru" />
        <div className="text-left pt-20 pb-10 px-5 mx-m24px mt-m40px bg-brand left-0 order-1 font-nouvelrbold md:p-60px md:w-52% md:mx-0 md:mt-120px md:rounded md:relative md:shrink-0 md:left-4%">
          <h2 className="text-3xl md:text-40px">{t('welcomeIn')}</h2>
          <h1 className="text-40px md:text-56px mb-6 md:mb-12">R Service Club!</h1>
          <p className="text-xl">{t('welcomeText')}</p>
        </div>
      </section>
  )
}

export default InformationsBanner;