import React from 'react';
import { useTranslation } from "react-i18next";
import { defaultStyles, DropdownIndicator } from '../../commons/selectStyles';
import Select from 'react-select';


const CompetitionSearchFilters = ({ availableCompetitions, availableServices, bir, setBir, filterCompetition, setFilterCompetition, filterService, setFilterService, allResults, setPage, tempBir, setTempBir }) => {
  const { t } = useTranslation();


  const competitionHandler = val => {
    setFilterCompetition(val);
    setPage(1);
  };

  const serviceHandler = val => {
    setFilterService(val);
    setPage(1);
  }

  const birHandler = val => {
    const fieldVal = val.currentTarget.value;
    setTempBir(fieldVal);

    if (fieldVal.length === 0 || fieldVal.length >= 8) {
      setBir(val.currentTarget.value);
      setPage(1);
    }
  }

  return (
      <section>
        <p className="mt-9 font-nouvelrbold text-base">{t('competitionsCounter')} {allResults}</p>
        <form className="flex flex-col md:flex-row md:justify-between w-full mt-11">
          <label className="text-sm md:w-1/3 mb-4 md:mb-0">
            {t('competitionName')}
            <Select name="competitionName" isMulti options={availableCompetitions} styles={defaultStyles} value={filterCompetition} onChange={(e) => competitionHandler(e)} components={{ DropdownIndicator }} placeholder={t('competitionChoose')} />
          </label>
          <label className="text-sm md:w-1/3 md:ml-14  mb-4 md:mb-0">
            {t('serviceName')}
            <Select name="serviceName" isMulti options={availableServices} styles={defaultStyles} value={filterService} onChange={(e) => serviceHandler(e)} components={{ DropdownIndicator }} placeholder={t('serviceChoose')} />
          </label>
          <label className="text-sm md:w-1/3 md:ml-14">
            {t('birNumber')}
            <input type="text" name="bir" placeholder={t('enterBirNumber')} value={tempBir} className="block w-full mt-3 py-2 border-0 border-b border-filterBorder outline-none placeholder-small" onChange={(e) => birHandler(e)} />
          </label>
        </form>
      </section>
  )
}

export default CompetitionSearchFilters;