import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { images } from '../../commons/functions';
import ArrowHeader from "../_shared/ArrowHeader";
import CompetitionManagementEditTitleBox from "./CompetitionManagementEditTitleBox";
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const cImages = images;

const CompetitionManagementEdit = () => {
  const { t } = useTranslation();
  const { urlId } = useParams();
  const { logout } = useContext(AuthContext);
  const [stageData, setStageData] = useState({});
  const [newCompetitionName, setNewCompetitionName] = useState('');
  const [newCompetitionId, setNewCompetitionId] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (urlId && urlId !== 'new') {
      JwtInterceptor
          .get(`${API_URL}Competitions/manage/edition/${urlId}`)
          .then((response) => {
            setStageData(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              logout();
            }
          });
    } else {
      const sObj = JSON.parse(sessionStorage.getItem('competition'));
      setNewCompetitionName(sObj.name);
      setNewCompetitionId(sObj.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
      <div>
        <ArrowHeader link="/competition-management" text={t('competitionsManage')} />
        <CompetitionManagementEditTitleBox
            image={urlId === 'new' ? `/images/${cImages[newCompetitionId]}` : `/images/${cImages[stageData.competitionId]}`}
            title={urlId === 'new' ? newCompetitionName : stageData.competitionName}
            stageData={stageData}
            urlId={urlId} />
      </div>
  );
}

export default CompetitionManagementEdit;