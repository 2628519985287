import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../_shared/Icon';
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = () => {
  const { t } = useTranslation();

  return (
      <div className="md:flex md:h-svh">
        <div className="relative md:w-1/2">
          <Icon id="rServiceClub" viewBox="0 0 78 30" width="78" height="30" clsWrap="hidden md:block mt-8 ml-10" />
          <div className="md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 px-10 md:px-0 py-5 md:py-0">
            <h1 className="mb-4 font-nouvelrbold text-3xl md:text-40px" dangerouslySetInnerHTML={{__html: t(`loginTitle`) }} />
            <p className="font-nouvelrbold md:font-nouvelr text-lg md:text-xl">{t('loginParagraph')}</p>
          </div>
        </div>
        <ResetPasswordForm />
      </div>
  );
};

export default ResetPassword;
