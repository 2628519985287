export const DefaultData = {
  user: {
    cardBalance: '',
    cardBalanceUpdatedOn: '',
    cardNumber: '',
    cardNumberConfirmedOn: '',
    email: '',
    firstName: '',
    id: '',
    image: '',
    lastName: '',
    phoneNumber: '',
    roles: [],
    service: '',
    userName: '',
  }
};

export const DataReducer = (data, { type, payload }) => {
  switch (type) {
    case 'SET_USER':
      return {
        ...data,
        user: {
          ...payload,
        }
      };
  default:
    return data;
  }
};
