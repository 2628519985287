import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import ArrowHeader from '../_shared/ArrowHeader';
import CompetitionProgress from "./CompetitionProgress";
import CompetitionText from "./CompetitionText";
import CompetitionPrev from "./CompetitionPrev";
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const Competition = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { id } = useParams();
  const [competition, setCompetition] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    JwtInterceptor
      .get(`${API_URL}Competitions/user/${id}`)
      .then((response) => {
        setCompetition(response.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          logout();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div className="mx-auto max-w-screen-lg">
        <ArrowHeader text={t('competitionCurrents')} />
        <CompetitionProgress competitionId={competition.competitionId} edition={competition.currentEdition ? competition.currentEdition.editionName : ''} progressData={competition.currentEdition} title={competition.competitionName} />

        <div className="md:flex">
          <CompetitionPrev prevEdition={competition.otherEditions} />
          <CompetitionText competitionId={competition.competitionId} id={competition.currentEdition ? competition.currentEdition.editionId : '' } />
        </div>

      </div>
  )
}

export default Competition;