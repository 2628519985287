import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Loader = ({ position, styles = 'fill-black' }) => {
  return (
      <Icon id="loader" viewBox="0 0 20 20" width="20" height="20" clsWrap={`absolute animate-spin ${styles} ${position}`} />
  );
}

Loader.defaultProps = {
  position: 'top-4 right-5',
  styles: ''
}

Loader.propTypes = {
  position: PropTypes.string,
  styles: PropTypes.string
}

export default Loader;