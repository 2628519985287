import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import EditTitle from '../_shared/EditTitle';
import Icon from "../_shared/Icon";
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const ProfilePassword = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [passFieldType, setPassFieldType] = useState('password');
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');

  const onSubmit = async (data) => {
    if (!loader) {
      setLoader(true);

      JwtInterceptor
          .post(`${API_URL}Users/updatePassword`, data)
          .then(() => {
            setPopUpText(t('passwordChangeSuccess'));
            setPopUpType('success');
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          }).finally(() => {
            setLoader(false);
            setPopUp(true);
          });
    }
  };

  const editHandler = (e) => {
    e.preventDefault();
    setIsEdit(prevState => !prevState);
    reset();
  }

  const changeType = () => {
    if (passFieldType === 'password') {
      setPassFieldType('text');
    } else {
      setPassFieldType('password');
    }
  };

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <div className="">
        <EditTitle editLinkText={t('change')} editFnc={(e) => editHandler(e)} isEdit={isEdit} isLoading={loader} sendFnc={handleSubmit(onSubmit)} text={t('password')} />
        {isEdit &&
          <form className="w-full md:w-1/2 mb-10" >
            <div className="relative mt-8 float-label-input">
              <input type={passFieldType} id="currentPassword" placeholder=" " {...register("currentPassword", { required: true, maxLength: 80, pattern: /^[a-zA-Z0-9.,!#@$%&()’*+/=?^_`{|}~-]+$/i})} className="block w-full py-1.5 outline-none border-b border-solid border-formBorderLogin bg-transparent" />
              <label htmlFor="currentPassword" className="absolute top-1.5 left-0 pointer-events-none transition duration-200">{t('currentPassword')} *</label>
              {errors.currentPassword && errors.currentPassword.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
              {errors.currentPassword && errors.currentPassword.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
              <Icon id="eye-black" viewBox="0 0 30 30" width="30" height="30" onClickFnc={changeType} clsWrap="absolute top-0 right-0 hover:cursor-pointer" />
            </div>
            <div className="relative mt-8 float-label-input">
              <input type={passFieldType} id="newPassword" placeholder=" " {...register("newPassword", { required: true, maxLength: 80, pattern: /^[a-zA-Z0-9.,!#@$%&()’*+/=?^_`{|}~-]+$/i})} className="block w-full py-1.5 outline-none border-b border-solid border-formBorderLogin bg-transparent" />
              <label htmlFor="newPassword" className="absolute top-1.5 left-0 pointer-events-none transition duration-200">{t('newPassword')} *</label>
              {errors.newPassword && errors.newPassword.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
              {errors.newPassword && errors.newPassword.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
              <Icon id="eye-black" viewBox="0 0 30 30" width="30" height="30" onClickFnc={changeType} clsWrap="absolute top-0 right-0 hover:cursor-pointer" />
            </div>
          </form>
        }
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </div>
  );
}

export default ProfilePassword;