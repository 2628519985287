import React, { useState } from 'react';
import { Transition } from '@headlessui/react'
import { useTranslation } from "react-i18next";

const InformationsDetails = () => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState(0);
  const questionsArray = [t('question1'), t('question2'), t('question3')];
  const answersArray = [t('questionAnswer1'), t('questionAnswer2'), t('questionAnswer3')];
  const questionsLength = questionsArray.length;
  let questions, answers = '';

  const questionHandler = (e, i) => {
    e.preventDefault();
    setDetail((i));
  }

  const questionSliderHandler = (e, direction) => {
    e.preventDefault();

    if (direction === 'next') {
      if (detail === questionsLength - 1) {
        setDetail(0);
      } else {
        setDetail(prevDetail => prevDetail + 1);
      }
    } else {
      if (detail === 0) {
        setDetail(questionsLength - 1);
      } else {
        setDetail(prevDetail => prevDetail - 1);
      }
    }
  }

  questions = questionsArray.map((question, i) => <li key={`q_${i}`}><a href="/#" className={`md:ml-0 pb-1.5 md:pb-0 pl-0 text-xl md:text-3xl font-nouvelrbold md:mb-6 md:inline-block transition ease-in-out duration-300 ${detail === i ? 'text-black border-b-4 md:border-b-0 md:border-l-4 border-b-brand md:border-l-brand border-solid md:pl-3' : 'text-gray hover:text-black hover:border-b-4 md:hover:border-b-0 md:hover:border-l-4 hover:border-l-brand hover:border-solid hover:pb-2 md:hover:pb-0 md:hover:pl-3'} ${i === 0 ? 'ml-0' : 'ml-6'}`} onClick={(e) => questionHandler(e, i)}>{question}</a></li>);
  answers = answersArray.map((answer, i) => <Transition
      key={`a_${i}`}
      show={detail === i}
      enter="transition-opacity ease-linear duration-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transform duration-0 transition ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
  ><p className="text-16px" dangerouslySetInnerHTML={{__html: answer }} /></Transition>);

  return (
      <section className="text-left mt-16 md:mt-36 md:flex">
        <div className="md:w-1/2 md:pr-60px">
          <p className="md:mb-10 text-center md:text-left">{t('getTheDetails')}</p>
          <ul className="mt-8 pb-2 md:pb-0 flex md:block whitespace-nowrap md:whitespace-normal overflow-x-scroll md:overflow-x-hidden overflow-y-hidden">
            {questions}
          </ul>
        </div>
        <div className="md:w-1/2 py-10 md:py-0 md:pl-20">
          {answers}
          <div className="flex justify-between items-end mt-10">
            <a href="/#" className="pb-2.5 font-nouvelrbold" onClick={(e) => questionSliderHandler(e, 'back')}>{t('previous')}</a>
            <a href="/#" className="font-nouvelrbold text-xl md:text-30px" onClick={(e) => questionSliderHandler(e, 'next')}>{t('next')}</a>
          </div>
        </div>
      </section>
  )
}

export default InformationsDetails;