import React from 'react';
import Menu from '../Menu';
import InformationsBanner from './InformationsBanner';
import InformationsDetails from "./InformationsDetails";
import InformationsHalfBanner from './InformationsHalfBanner';

const Informations = () => {
  return (
      <>
        <Menu />
        <div className="pt-4 md:py-4 md:py-6">
          <InformationsBanner />
          <InformationsDetails />
          <InformationsHalfBanner />
        </div>
      </>
  )
}

export default Informations;