import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Pagination = ({ actualPage, allPage, setPage }) => {
  const { t } = useTranslation();

  const setPageHandler = (direction) => {
    if (direction === 'next') {
      setPage(prevState => prevState + 1);
    } else {
      setPage(prevState => prevState - 1);
    }
  }

  return(
      <div className="flex items-center justify-between mt-10">
        <p className="text-xl md:text-30px font-nouvelrbold">{actualPage} <span className="text-gray"> / </span>{allPage} </p>
        <p className="font-nouvelrbold text-right">
          {actualPage < allPage &&
            <span className="block text-xl md:text-30px hover:cursor-pointer" onClick={() => setPageHandler('next')}>{t('paginationNextPage')}</span>
          }
          {actualPage > 1 &&
            <span className="block mt-4 text-lg md:text-xl hover:cursor-pointer" onClick={() => setPageHandler('prev')}>{t('paginationPrevPage')}</span>
          }
        </p>
      </div>
  )
}

Pagination.defaultProps = {
  actualPage: '',
  allPage: '/#',
}

Pagination.propTypes = {
  actualPage: PropTypes.number.isRequired,
  allPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
}

export default Pagination;