import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from './_shared/AuthContext';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ setBlurePage }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return(
      <>
        <Header setBlurePage={setBlurePage} />
        <main className="mx-auto max-w-screen-lg py-4 md:py-6 px-6 lg:px-60px font-nouvelr">
          <Outlet />
        </main>
        <Footer />
      </>
  )
}

export default Layout