import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ButtonLink from "../_shared/ButtonLink";
import Icon from "../_shared/Icon";
import Loader from '../_shared/Loader';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [loader, setLoader] = useState(false);
  const [passFieldType, setPassFieldType] = useState('password');
  const [success, setSuccess] = useState(false);
  const [urlParams] = useSearchParams();

  const onSubmit = async (data) => {
    if (!loader) {
      const dataJson = {
        email: urlParams.get("email"),
        token: urlParams.get("token").replaceAll(' ', '+'),
        newPassword: data.newPassword
      };

      setLoader(true);
      setError(false);

      axios
        .post(`${API_URL}Users/newPassword`, dataJson)
        .then(() => {
          setSuccess(true);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setErrorText(errorTxt);
          } else {
            setErrorText(t('errDefault'));
          }
          setError(true);
        }).finally(() => {
          setLoader(false);
        });
    }
  };

  const changeType = () => {
    if (passFieldType === 'password') {
      setPassFieldType('text');
    } else {
      setPassFieldType('password');
    }
  };


  return (
      <div className="relative md:w-1/2 mt-5 md:mt-0 py-20 md:py-0 bg-loginBg bg-cover text-base">
        {success ?
          <form className="bg-loginFormBg font-nouvelr md:w-6/12 md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 mx-5 md:mx-0 px-12 py-8 md:py-72px rounded-3xl backdrop-blur">
            <p className="mb-8 font-nouvelrbold text-30px text-white text-center">{t('resetPasswordSuccess')}</p>
            <p className="mt-8 p-4 text-green-700 text-center border border-green-700 border-solid">{t('successResetPassword')}</p>
            <ButtonLink anchor={t('loginFormTitle')} href="/login" styles="block w-full mt-8 p-4 border border-white font-nouvelrbold text-white text-center text-basee" />
          </ form>
        :
          <form onSubmit={handleSubmit(onSubmit)} className="bg-loginFormBg font-nouvelr md:w-6/12 md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 mx-5 md:mx-0 px-12 py-8 md:py-72px rounded-3xl backdrop-blur">
            <p className="mb-8 font-nouvelrbold text-30px text-white text-center">{t('resetPassword')}</p>
            <div className="relative mt-8 float-label-input">
              <input type={passFieldType} id="newPassword" placeholder=" " {...register("newPassword", { required: true, maxLength: 80, pattern: /^[a-zA-Z0-9.,!#@$%&()’*+/=?^_`{|}~-]+$/i})} className="block w-full py-1.5 text-white outline-none border-b border-solid border-formBorderLogin bg-transparent" />
              <label htmlFor="newPassword" className="absolute top-1.5 left-0 text-white pointer-events-none transition duration-200">{t('newPassword')} *</label>
              {errors.newPassword && errors.newPassword.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
              {errors.newPassword && errors.newPassword.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
              <Icon id="eye" viewBox="0 0 30 30" width="30" height="30" onClickFnc={changeType} clsWrap="absolute top-0 right-0 hover:cursor-pointer" />
            </div>
            {error && <p className="mt-8 p-4 text-error text-center border border-error border-solid">{errorText}</p>}
            <button type="submit" className="relative w-full mt-8 p-4 border font-nouvelrbold text-white text-basee">
              {t('change')}
              {loader && <Loader styles="fill-white" />}
            </button>
          </form>
        }
      </div>
  );
};

export default ResetPasswordForm;