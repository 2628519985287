import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../commons/functions';
import CompetitionManagementBox from "./CompetitionManagementBox";
import LoaderBig from '../_shared/LoaderBig';
import Menu from '../Menu';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const cImages = images;

const CompetitionManagement = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [competitionsData, setCompetitionsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    const getCompetitions = () => {
      setLoader(true);

      JwtInterceptor
          .get(`${API_URL}Competitions/manage`)
          .then((response) => {
            setCompetitionsData(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setLoader(false);
            setReload(false);
          });
    }

    if (reload) getCompetitions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
      <div>
        <Menu />
        <p className="mt-9 font-nouvelrbold text-base">{t('competitions')}</p>
        {loader && <LoaderBig />}
        {competitionsData.length > 0 && competitionsData.map(competition => <CompetitionManagementBox competition={competition} key={competition.competitionId} setReload={setReload} images={cImages} />)}
      </div>
  );
}

export default CompetitionManagement;