import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { format } from "date-fns";
import { reverseDate } from "../../commons/functions";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../_shared/Button';
import ButtonLink from '../_shared/ButtonLink';
import Icon from '../_shared/Icon';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionManagementEditForm = ({ stageData, urlId }) => {
  const { t } = useTranslation();
  const { control, register, reset, handleSubmit, formState: { errors } } = useForm();
  const [booster, setBooster] = useState(false);
  const [dateTo, setDateTo] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [boosterDateFrom, setBoosterDateFrom] = useState('');
  const [boosterDateTo, setBoosterDateTo] = useState('');
  const [boosterPriceFactor, setBoosterPriceFactor] = useState('');
  const [formData, setFormData] = useState({});
  const [popUp, setPopUp] = useState(false);
  const [popUpButtons, setPopUpButtons] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [sessionData, setSessionData] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  let ignoreWarning = false;

  useEffect(() => {
    setSessionData(JSON.parse(sessionStorage.getItem('competition')));

    if (stageData.dateFrom) setDateFrom(reverseDate(stageData.dateFrom));
    if (stageData.dateTo) setDateTo(stageData.dateTo);
    if (stageData.booster) {
      setBooster(true);
      if (stageData.booster.dateFrom) setBoosterDateFrom(stageData.booster.dateFrom);
      if (stageData.booster.dateTo) setBoosterDateTo(stageData.booster.dateTo);
      if (stageData.booster.boosterPriceFactor) setBoosterPriceFactor(stageData.booster.boosterPriceFactor);
    }
    reset(stageData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);

  const onSubmit = (data) => {
    const sObj = JSON.parse(sessionStorage.getItem('competition'));
    let boosterData = {};

    if (loader) return;

    setPopUpButtons(false);
    setPopUp(false);
    setFormData(data);

    if (booster) {
      boosterData = {
        "dateFrom": format(data.boosterDateFrom, "yyyy-MM-dd"),
        "dateTo": format(data.boosterDateTo, "yyyy-MM-dd"),
        "boosterPriceFactor": data.boosterPriceFactor
      }
    } else {
      boosterData = null;
    }

    const sendObj = {
      "competitionId": sObj.id,
      "targetPerServiceAdvisor": data.targetPerServiceAdvisor,
      "booster": boosterData,
      "name": data.name,
      "dateFrom": format(data.dateFrom, "yyyy-MM-dd"),
      "dateTo": format(data.dateTo, "yyyy-MM-dd")
    }

    if (urlId === 'new') {
      setLoader(true);
      JwtInterceptor
          .post(`${API_URL}Competitions/manage/edition?ignoreWarning=${ignoreWarning}`, sendObj)
          .then((response) => {
            if (response.data.success) {
              setPopUpText(t('dataChangeSuccess'));
              setPopUpType('success');
            } else {
              if (response.data.errors) {
                const errorTxt = response.data.errors.message.map((error, i) => <span key={`p_${i}`}>{error}</span>);
                setPopUpText(errorTxt);
                setPopUpType('error');
              } else if (response.data.warnings) {
                const errorTxt = response.data.warnings.message.map((warning, i) => <span key={`w_${i}`}>{warning}</span>);
                setPopUpButtons(true);
                setPopUpText(errorTxt);
                setPopUpType('error');
              }
            }
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          })
          .finally(() => {
            setPopUp(true);
            setLoader(false);
          });
    } else {
      sendObj.id = urlId;
      setLoader(true);

      JwtInterceptor
          .put(`${API_URL}Competitions/manage/edition?ignoreWarning=${ignoreWarning}`, sendObj)
          .then((response) => {
            if (response.data.success) {
              setPopUpText(t('dataChangeSuccess'));
              setPopUpType('success');
            } else {
              if (response.data.errors) {
                const errorTxt = response.data.errors.message.map((error, i) => <span key={`p_${i}`}>{error}</span>);
                setPopUpText(errorTxt);
                setPopUpType('error');
              } else if (response.data.warnings) {
                const errorTxt = response.data.warnings.message.map((warning, i) => <span key={`w_${i}`}>{warning}</span>);
                setPopUpButtons(true);
                setPopUpText(errorTxt);
                setPopUpType('error');
              }
            }
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          })
          .finally(() => {
            setPopUp(true);
            setLoader(false);
          });
    }
  }

  const boosterHandler = () => {
    setBooster(prevState => !prevState);
  }

  const popUpCloseHandler = () => {
    setPopUp(false);
    if (popUpType === 'success') navigate("/competition-management");
  }

  const sendFormHandler = () => {
    ignoreWarning = true;
    onSubmit(formData);
  }

  return (
    <div>
      <h3 className="mt-10 font-nouvelrbold text-3xl md:text-40px">{t('stageEditing')}</h3>
      <form className="text-base">

        <p className="mt-8 mb-8 font-nouvelrbold">{t('stageName')}</p>
        <div className="relative md:w-5/12 float-label-input">
          <input type="text" id="name" placeholder=" " defaultValue={stageData.name} {...register("name", { required: true, maxLength: 60, pattern: /^[^\s]+[^^<>&]{2,50}$/i})}  className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
          <label htmlFor="name" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('name')} *</label>
          {errors.name && errors.name.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
          {errors.name && errors.name.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
        </div>

        <p className="mt-8 mb-8 font-nouvelrbold">{t('stageDuration')}</p>
        <div className="flex md:w-5/12">
          <div className={`relative float-label-input ${dateFrom ? 'isValue' : ''}`}>
            {/*<DatePicker type="text" id="dateFrom" placeholder=" " defaultValue={stageData.dateFrom} {...register("dateFrom", { required: true })} dateFormat="dd-MM-yyyy" className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2" selected={dateFrom} onChange={(date) => setDateFrom(date)} />*/}

            <Controller
              name="dateFrom"
              control={control}
              defaultValue={dateFrom}
              errors={errors}
              rules={{ required: true }}
              render={({ field: { onChange, value} }) => (
                <DatePicker
                    type="text"
                    id="dateFrom"
                    placeholder=" "
                    dateFormat="dd-MM-yyyy"
                    className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2"
                    selected={value}
                    onChange={
                      (date) => {
                        onChange(date);
                        setDateFrom(date);
                      }
                    }
                />
              )}
            />
            <label htmlFor="dateFrom" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('dateStart')} *</label>
            <Icon id="calendar" viewBox="0 0 30 30" width="30" height="30" clsWrap="absolute right-0 top-1" />
            {errors.dateFrom && errors.dateFrom.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
          </div>
          <div className={`relative float-label-input ml-5 ${dateTo ? 'isValue' : ''}`}>
            {/*<DatePicker type="text" id="dateTo" placeholder=" " defaultValue={dateTo} {...register("dateTo", { required: true })} dateFormat="dd-MM-yyyy" className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2" selected={dateTo} onChange={(date) => setDateTo(date)} />*/}
            <Controller
                name="dateTo"
                control={control}
                defaultValue={dateTo}
                errors={errors}
                rules={{ required: true }}
                render={({ field: { onChange, value} }) => (
                    <DatePicker
                        type="text"
                        id="dateTo"
                        placeholder=" "
                        dateFormat="dd-MM-yyyy"
                        className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2"
                        selected={value}
                        onChange={
                          (date) => {
                            onChange(date);
                            setDateTo(date);
                          }
                        }
                    />
                )}
            />
            <label htmlFor="dateTo" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('dateEnd')} *</label>
            <Icon id="calendar" viewBox="0 0 30 30" width="30" height="30" clsWrap="absolute right-0 top-1" />
            {errors.dateTo && errors.dateTo.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
          </div>
        </div>

        <p className="mt-8 mb-8 font-nouvelrbold">{t('salesGoal')}</p>
        <div className="flex flex-col md:flex-row items-center">
          {t('employee')}
          <span className="mx-30px mb-5 md:mb-0">x</span>
          <div className="relative w-3/12 md:w-2/12 float-label-input">
            <input type="text" id="targetPerServiceAdvisor" placeholder=" " defaultValue={stageData.targetPerServiceAdvisor} {...register("targetPerServiceAdvisor", { required: true, pattern: /^[0-9.,]+$/i})} maxLength="8" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
            <label htmlFor="targetPerServiceAdvisor" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{sessionData.targetType === 'quantity' ? t('quantity') : t('amount') } *</label>
          </div>
          {sessionData.targetType === 'quantity' ? '' : 'zł' }
          <span className="mx-30px">x</span>
          {t('monthDuration')}
        </div>
        <div className="">
          {errors.targetPerServiceAdvisor && errors.targetPerServiceAdvisor.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
          {errors.targetPerServiceAdvisor && errors.targetPerServiceAdvisor.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
        </div>

        {sessionData.prizeType === 'quantity' &&
          <div className="flex flex-col mt-12">
            {!booster ?
              <Button anchor={t('addBooster')} href="/#" iconLeft="plusThin" styles="w-fit" onClickFnc={boosterHandler} />
            :
              <div className="px-4 pb-6 bg-boosterBg border-t border-solid border-grayBorder">
                <p className="mt-8 mb-4 font-nouvelrbold">{t('boosterTime')}</p>
                <div className="flex md:w-5/12">
                  <div className={`relative float-label-input ${boosterDateFrom ? 'isValue' : ''}`}>
                    {/*<DatePicker type="text" id="boosterDateFrom" placeholder=" " defaultValue={boosterDateFrom} {...register("boosterDateFrom", { required: true })} dateFormat="dd-MM-yyyy" className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2" selected={boosterDateFrom} onChange={(date) => setBoosterDateFrom(date)} />*/}
                    <Controller
                        name="boosterDateFrom"
                        control={control}
                        defaultValue={boosterDateFrom}
                        errors={errors}
                        rules={{ required: true }}
                        render={({ field: { onChange, value} }) => (
                            <DatePicker
                                type="text"
                                id="boosterDateFrom"
                                placeholder=" "
                                dateFormat="dd-MM-yyyy"
                                className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2"
                                selected={value}
                                onChange={
                                  (date) => {
                                    onChange(date);
                                    setBoosterDateFrom(date);
                                  }
                                }
                            />
                        )}
                    />
                    <label htmlFor="boosterDateFrom" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('dateStart')} *</label>
                    <Icon id="calendar" viewBox="0 0 30 30" width="30" height="30" clsWrap="absolute right-0 top-1" />
                    {errors.boosterDateFrom && errors.boosterDateFrom.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
                  </div>
                  <div className={`relative float-label-input ml-5 ${boosterDateTo ? 'isValue' : ''}`}>
                    {/*<DatePicker type="text" id="boosterDateTo" placeholder=" " defaultValue={boosterDateTo} {...register("boosterDateTo", { required: true })} dateFormat="dd-MM-yyyy" className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2" selected={boosterDateTo} onChange={(date) => setBoosterDateTo(date)} />*/}
                    <Controller
                        name="boosterDateTo"
                        control={control}
                        defaultValue={boosterDateTo}
                        errors={errors}
                        rules={{ required: true }}
                        render={({ field: { onChange, value} }) => (
                            <DatePicker
                                type="text"
                                id="boosterDateTo"
                                placeholder=" "
                                dateFormat="dd-MM-yyyy"
                                className="relative z-10 block w-full py-1.5 bg-transparent outline-none border-b border-solid border-grayBorder2"
                                selected={value}
                                onChange={
                                  (date) => {
                                    onChange(date);
                                    setBoosterDateTo(date);
                                  }
                                }
                            />
                        )}
                    />
                    <label htmlFor="boosterDateTo" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('dateEnd')} *</label>
                    <Icon id="calendar" viewBox="0 0 30 30" width="30" height="30" clsWrap="absolute right-0 top-1" />
                    {errors.boosterDateTo && errors.boosterDateTo.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
                  </div>
                </div>

                <p className="mt-8 mb-8 font-nouvelrbold">{t('newPrizeValue')}</p>
                <div className={`flex flex-col md:flex-row items-center ${errors.boosterPriceFactor ? '' : 'mb-14'}`}>
                  {t('newPrizeValueLabel')}
                  <span className="mx-30px mb-5 md:mb-0">x</span>
                  <div className="relative w-3/12 md:w-1/12 mr-30px float-label-input">
                    <input type="text" id="boosterPriceFactor" placeholder=" " defaultValue={boosterPriceFactor} {...register("boosterPriceFactor", { required: true, pattern: /^[0-9.,]+$/i})} maxLength="8" className="block w-full py-1.5 outline-none bg-transparent border-b border-solid border-grayBorder2" />
                    <label htmlFor="boosterPriceFactor" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('quantity')} *</label>
                  </div>
                  {t('baseReward')}
                </div>
                <div className="mb-14">
                  {errors.boosterPriceFactor && errors.boosterPriceFactor.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
                  {errors.boosterPriceFactor && errors.boosterPriceFactor.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
                </div>
                <Button anchor={t('deleteBooster')} href="/#" iconLeft="x" styles="w-fit" onClickFnc={boosterHandler} />
              </div>
            }
          </div>
        }

        <div className="flex flex-col md:flex-row mt-12">
          <Button anchor={t('confirmTheChangesMade')} iconLeft="check" loading={loader} onClickFnc={handleSubmit(onSubmit)} />
          <ButtonLink href="/competition-management" anchor={t('rejectTheChangesMade')} iconLeft="x" styles="mt-8 mb-8 md:mt-0 md:mb-0 md:ml-8" />
        </div>

      </form>
      <PopUp buttons={popUpButtons} open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} sendFnc={sendFormHandler} />
    </div>
  );
}

export default CompetitionManagementEditForm;