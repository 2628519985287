import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const JwtInterceoptor = axios.create({});

JwtInterceoptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("tokens"));
  config.headers["Authorization"] = `bearer ${tokensData.token}`;
  return config;
});

JwtInterceoptor.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // console.log('ERROR', error);
      if (error.response.status === 401) {
        const authData = JSON.parse(localStorage.getItem("tokens"));
        const payload = {
          token: authData.token,
          refreshToken: authData.refreshToken,
        };
        try {
          let apiResponse = await axios.post(
              `${API_URL}Users/refreshToken`,
              payload
          );
          localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
          error.config.headers[
              "Authorization"
              ] = `bearer ${apiResponse.data.token}`;
          return axios(error.config);
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
);

export default JwtInterceoptor;
