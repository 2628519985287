import React from 'react';
import Icon from './Icon';

function LoaderBig() {
  return (
      <div className="relative w-full h-screen">
        <div className="absolute top-16 left-1/2 transform -translate-x-1/2">
          <Icon id="loader" viewBox="0 0 20 20" width="100" height="100" clsWrap={`animate-spin`} />
        </div>
      </div>
  );
}

export default LoaderBig;
