import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import Icon from "../_shared/Icon";

const ArrowHeader = ({ link, text }) => {
  return (
      <h3 className="mb-6 md:mb-16 text-xl md:text-30px font-nouvelrbold">
        <Link to={link} className="flex items-center">
          <Icon id="leftArr" viewBox="0 0 22 21" width="22" height="21" />
          <span className="ml-3">{text}</span>
        </Link>
      </h3>
  )
}

ArrowHeader.defaultProps = {
  link: '/',
  text: ''
}

ArrowHeader.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default ArrowHeader;