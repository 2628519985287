import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Select, { components } from 'react-select';
import EditTitle from "../_shared/EditTitle";
import Icon from "../_shared/Icon";
import Loader from "../_shared/Loader";
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: 'auto',
    marginTop: '0.75rem',
    border: 'none',
    borderBottom: '1px solid #000',
    borderRadius: 0,
    outline: 'none',
    boxShadow: 'none',
    fontSize: '16px',
    lineHeight: '16px',
    '&:hover' : {
      borderBottom: '1px solid #000',
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    outline: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: 0,
    color: '#978b7f',
    fontSize: '16px',
    lineHeight: '16px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'inherit',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#efdf00' : 'inherit',
    color: state.isSelected ? '#000' : '#000',
    '&:hover' : {
      backgroundColor: '#efdf00',
    }
  }),
};

const DropdownIndicator = props => {
  return (
      <components.DropdownIndicator {...props}>
        <Icon id="upArr" viewBox="0 0 32 32" width="30" height="30" clsWrap="m-m5px rotate-180 fill-black" />
      </components.DropdownIndicator>
  );
};

const AddUserForm = ({ setProfileImg }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, resetField, formState: { errors }, control } = useForm();
  const { id } = useParams();
  const [competitions, setCompetitions] = useState([]);
  const [competitionsChx, setCompetitionChx] = useState([]);
  const [competitionsExcluded, setCompetitionsExcluded] = useState([]);
  const [loader, setLoader] = useState(false);
  const [passFieldType, setPassFieldType] = useState('password');
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [selectRole, setSelectRole] = useState('');
  const [services, setServices] = useState([]);
  const [servicesVal, setServicesVal] = useState([]);
  const [userData, setUserData] = useState('');
  const navigate = useNavigate();
  const formData = new FormData();
  const role = JSON.parse(localStorage.getItem("tokens")).roles[0];
  const roles = [
    {value: 'admin', label: 'admin'},
    {value: 'mop', label: 'mop'},
    {value: 'serviceChief', label: 'serviceChief'},
    {value: 'serviceAdvisor', label: 'serviceAdvisor'}
  ]

  useEffect(() => {
    if (id) {
      JwtInterceptor
          .get(`${API_URL}Users/${id}`)
          .then((respond) => {
            setUserData(respond.data);
            setProfileImg(respond.data.image);
            reset();
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          });
    }

    if (role === "Admin") {
      JwtInterceptor
          .get(`${API_URL}Services`)
          .then((respond) => {
            setServices(respond.data);
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          });
    }

    if (role === "ServiceChief") {
      JwtInterceptor
          .get(`${API_URL}Competitions/user`)
          .then((respond) => {
            const currentCompetitions = respond.data.filter(competition => competition.currentEdition !== null);
            setCompetitions(currentCompetitions);
            setCompetitionChx(currentCompetitions.map(c => Number(c.currentEdition.editionId)));
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (services && services.length > 0) {
      const servicesTemp = [];

      services.forEach(item => {
        servicesTemp.push({
          value: item.id,
          label: item.name
        });
      });

      setServicesVal(servicesTemp);
    }
  }, [services]);

  const onSubmit = async (data) => {
    if (!loader) {
      setLoader(true);

      if (id) {
        formData.append('UserId', id);
        formData.append('Email', data.Email);
        formData.append('FirstName', data.FirstName);
        formData.append('ImageFile', data.ImageFile[0]);
        formData.append('LastName', data.LastName);
        formData.append('Password', data.Password);
        formData.append('PhoneNumber', data.PhoneNumber);
        formData.append('CardNumber', data.CardNumber);

        JwtInterceptor
            .post(`${API_URL}Users/update`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(() => {
              setPopUpText(t('editUserSuccess'));
              setPopUpType('success');
            })
            .catch(function (error) {
              if (error.response.data.errors) {
                const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
                setPopUpText(errorTxt);
              } else {
                setPopUpText(t('errDefault'));
              }
              setPopUpType('error');
            }).finally(() => {
              setLoader(false);
              setPopUp(true);
        });
      } else {
        formData.append('Email', data.Email);
        formData.append('FirstName', data.FirstName);
        formData.append('ImageFile', data.ImageFile[0]);
        formData.append('LastName', data.LastName);
        formData.append('Password', data.Password);
        formData.append('PhoneNumber', data.PhoneNumber);
        formData.append('UserName', data.UserName);
        formData.append('CardNumber', data.CardNumber);

        if (role === 'Admin' && data.Roles) formData.append('Roles', data.Roles);
        if (role === 'Admin' && data.Service) formData.append('ServiceId', data.Service.value);
        if (role === "ServiceChief") {
          competitionsExcluded.forEach(c => formData.append('ExcludedFromEditions', c));
        }

        JwtInterceptor
            .post(`${API_URL}Users/create`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(() => {
              setPopUpText(t('addUserSuccess'));
              setPopUpType('success');
            })
            .catch(function (error) {
              if (error.response.data.errors) {
                const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
                setPopUpText(errorTxt);
              } else {
                setPopUpText(t('errDefault'));
              }
              setPopUpType('error');
            }).finally(() => {
              setLoader(false);
              setPopUp(true);
        });
      }
    }
  };

  const changeType = () => {
    if (passFieldType === 'password') {
      setPassFieldType('text');
    } else {
      setPassFieldType('password');
    }
  };

  const popUpCloseHandler = () => {
    setPopUp(false);

    if (popUpType === 'error') return;

    if (popUpType === 'success') navigate("/team");
  }

  const roleHandler = val => {
    setSelectRole(val.value)

    if (val.value === "mop" || val.value === "admin") {
      resetField("Service");
    }
  }

  const checkboxHandler = e => {
    const checked = e.currentTarget.checked;

    if (checked) {
      setCompetitionChx([...competitionsChx, Number(e.currentTarget.value)]);
      setCompetitionsExcluded(competitionsExcluded.filter(c => Number(c) !== Number(e.currentTarget.value)));
    } else {
      setCompetitionChx(competitionsChx.filter(c => Number(c) !== Number(e.currentTarget.value)));
      setCompetitionsExcluded([...competitionsExcluded, Number(e.currentTarget.value)]);
    }
  }

  return (
      <>
        {id ?
          <EditTitle text={t('editUserTitle')} />
        :
          <EditTitle text={t('addUserTitle')} />
        }
        <form className="w-full mb-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative lg:w-5/12 float-label-input mt-6">
            <input type="text" id="FirstName" placeholder=" " defaultValue={id ? userData.firstName : ''} {...register("FirstName", { required: true, maxLength: 40, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} maxLength="40" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
            <label htmlFor="FirstName" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('nameContactForm')} *</label>
            {errors.FirstName && errors.FirstName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.FirstName && errors.FirstName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <div className="relative lg:w-5/12 float-label-input mt-6">
            <input type="text" id="LastName" placeholder=" " defaultValue={id ? userData.lastName : ''} {...register("LastName", { required: true, maxLength: 50, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} maxLength="50" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
            <label htmlFor="LastName" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('surname')} *</label>
            {errors.LastName && errors.LastName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.LastName && errors.LastName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <div className="relative lg:w-5/12 float-label-input mt-6">
            <input type="text" id="PhoneNumber" placeholder=" " defaultValue={id ? userData.phoneNumber : ''} {...register("PhoneNumber", { maxLength: 9, pattern: /^[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{3}$/i})} maxLength="9" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
            <label htmlFor="PhoneNumber" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('phone')}</label>
            {errors.PhoneNumber && errors.PhoneNumber.type === "pattern" && <span className="text-error">{t('errFieldFormat')}</span>}
          </div>
          <div className="relative lg:w-5/12 float-label-input mt-6">
            <input type={id ? "hidden" : "text"} id="Email" placeholder=" " defaultValue={id ? userData.email : ''} {...register("Email", { required: true, maxLength: 80, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z-]+)+$/i})} maxLength="80" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
            {!id && <label htmlFor="Email" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('email')} *</label>}
            {errors.Email && errors.Email.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.Email && errors.Email.type === "pattern" && <span className="text-error">{t('errFieldEmail')}</span>}
          </div>
          <div className="relative lg:w-5/12 float-label-input mt-6">
            <input type="text" id="UserName" placeholder=" " defaultValue={id ? userData.userName : ''} {...register("UserName", { required: !id, maxLength: 40, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} maxLength="40" disabled={!!id} className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
            <label htmlFor="UserName" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('userName')} *</label>
            {errors.UserName && errors.UserName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.UserName && errors.UserName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          {role === 'Admin' &&
            <div className="relative lg:w-5/12 float-label-input mt-6">
              <input type="text" id="CardNumber" placeholder=" "  defaultValue={id ? userData.cardNumber : ''} {...register("CardNumber", { required: false, maxLength: 10 })} maxLength="10" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
              <label htmlFor="CardNumber" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('cardNumber')}</label>
              {errors.CardNumber && errors.CardNumber.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
              {errors.CardNumber && errors.CardNumber.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
            </div>
          }
          {(!id || (id && role === 'Admin')) &&
            <div className="relative lg:w-5/12 float-label-input mt-6">
              <input type={passFieldType} id="Password" placeholder=" " {...register("Password", { required: (id && role === 'Admin') ? false : true, maxLength: 80, pattern: /^[a-zA-Z0-9.,!#@$%&()’*+/=?^_`{|}~-]+$/i})} maxLength="80" className="block w-full py-1.5 outline-none border-b border-solid border-grayBorder2" />
              <label htmlFor="Password" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('password')} {id && role === 'Admin' ? '' : '*'}</label>
              {errors.Password && errors.Password.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
              {errors.Password && errors.Password.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
              <Icon id="eye-black" viewBox="0 0 30 30" width="30" height="30" onClickFnc={changeType} clsWrap="absolute top-0 right-0 hover:cursor-pointer" />
            </div>
          }
          {!id &&
            <>
              {role === 'Admin' &&
                <>
                  <div className="relative lg:w-5/12 float-label-input isValue mt-6">
                    <Controller
                        name="Roles"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <>
                              <label htmlFor="Roles" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('role')} *</label>
                              <Select
                                  value={roles.find(r => value === r.value)}
                                  options={roles} // Replace with your options array
                                  styles={customStyles}
                                  components={{ DropdownIndicator }}
                                  // onChange={val => onChange(roleHandler(val))}
                                  onChange={val => [onChange(val.value), roleHandler(val)]}
                              />
                            </>
                        )}
                    />
                    {errors.Roles && errors.Roles.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
                  </div>
                  {(selectRole === 'serviceChief' || selectRole === 'serviceAdvisor') &&
                    <div className="relative lg:w-5/12 float-label-input isValue mt-6">
                      <Controller
                          name="Service"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                              <>
                                <label htmlFor="Service" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('service')} *</label>
                                <Select
                                    {...field}
                                    options={servicesVal} // Replace with your options array
                                    styles={customStyles}
                                    components={{ DropdownIndicator }}
                                />
                              </>
                          )}
                      />
                      {errors.Service && errors.Service.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
                    </div>
                  }
                </>
              }
              {role === "ServiceChief" && competitions.length > 0 &&
                <div className="relative mt-6">
                  <p className="mb-2">{t('addUserCompetitions')}</p>
                  {competitions.map(chx => {
                    return (
                      <div className="flex items-center mt-3" key={`chk_${chx.competitionId}`}>
                        <input className="h-5 w-5 mr-2 accent-brand" type="checkbox" name={chx.competitionId} id={chx.competitionId} value={chx.currentEdition.editionId} onChange={(e) => checkboxHandler(e)} checked={competitionsChx.includes(chx.currentEdition.editionId)} />
                        <label className="text-sm" htmlFor={chx.competitionId}>{chx.competitionName} ({chx.currentEdition.editionName})</label>
                      </div>
                    )
                  })}
                </div>
              }
            </>
          }

          <p className="flex text-base mt-6">
            <span className="inline-block mr-4 font-nouvelr">{t('imageFile')}</span>
            <input type="file" {...register("ImageFile")} defaultValue="" className={`inline-block outline-none bg-transparent`} />
          </p>
          {id ?
            <button type="submit" className={`relative w-fit mt-8 p-4 border font-nouvelrbold text-black transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand ${loader ? 'pr-12' : ''}`}>
              {t('save')}
              {loader && <Loader />}
            </button>
          :
            <button type="submit" className={`relative w-fit mt-8 p-4 border font-nouvelrbold text-black transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand ${loader ? 'pr-12' : ''}`}>
              {t('add')}
              {loader && <Loader />}
            </button>
          }
        </form>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </>
  );
}

export default AddUserForm;