import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLink from './ButtonLink';
import PropTypes from "prop-types";

const ProgressBarItem = ({ absence, dates, edition, goal, id, lastImportDate, percent, prize, percentageScore, result, targetType, title, type }) => {
  let targetPostfix = percentageScore ? '%' : 'zł';
  const { t } = useTranslation();
  let color = 'bg-progressBarMini0';

  if (percent < 30) {
    color = 'bg-progressBarBig0';
  } else if (percent >= 30 && percent < 60) {
    color = 'bg-progressBarBig30';
  } else if (percent >= 60 && percent < 100) {
    color = 'bg-progressBarBig60';
  } else {
    color = 'bg-progressBarBig100';
  }

  return (
        <div className="w-full">
            <p className="font-nouvelrbold md:font-nouvelr text-sm md:text-base">{dates}</p>
            <h3 className={`mt-3 md:mt-8 mb-1.5 md:mb-4 font-nouvelrbold ${type === 'details' ? 'text-40px md:text-56px' : 'text-3xl md:text-40px'}`}>{title}<br />{edition}</h3>
            <div className="flex items-center justify-between mt-2 md:mt-4">
              {/*<p className="text-3xl md:text-40px font-nouvelrbold">{percent}%</p>*/}
              <p className="text-3xl md:text-40px font-nouvelrbold"></p>
              {percent >= 100 &&
                <p className="">
                  <span className={`block text-right ${type === 'details' ? 'text-base' : 'font-nouvelrbold md:font-nouvelr text-sm md:text-base'}`}>{percent >= 100 ? t('yourPrize') : t('progressBarPotentialReward')}</span>
                  <span className={`block text-right text-xl ${type === 'details' ? '' : 'text-lg font-nouvelrbold md:text-xl'}`}>{prize}</span>
                </p>
              }
            </div>
            <div className={`w-full h-4 mt-2 md:mt-4 mb-4 bg-progressBarMiniBg rounded-2xl ${type === 'details' ? 'md:mb-4' : 'md:mb-8'}`}>
                <span style={{'width': percent + '%'}} className={`block w-53px h-4 m-0 rounded-2xl max-w-full ${color}`}></span>
            </div>
            <div className={type === 'details' ? 'md:flex md:items-center md:justify-between' : 'flex items-center justify-between'}>
              <p className={type === 'details' ? 'flex justify-between md:block' : ''}>
                <span className={`block ${type === 'details' ? 'text-base text-right md:text-left' : 'font-nouvelrbold md:font-nouvelr text-sm md:text-base'}`}>{t('progressBarActualResult')}</span>
                <span className="text-xs">{lastImportDate}</span>
                <span className={`block ${type === 'details' ? 'text-right text-right md:text-left text-xl' : 'font-nouvelrbold text-lg md:text-xl'}`}>{result} {targetType === 'value' ? targetPostfix : 'szt.'}</span>
              </p>
              {type === '' &&
                <>
                  <p>
                    <span className="block font-nouvelrbold md:font-nouvelr text-sm md:text-base">{goal > 0 ? t('progressBarMissingTheTarget') : t('progressBarObjective')}</span>
                    {id === 'filters' ?
                      <span className="block font-nouvelrbold text-lg md:text-xl">
                        {absence !== -1 ?
                          <>
                            {goal > 0 ? `${goal} szt.` : t('objectiveAchievedOnly')}
                          </>
                        :
                          <>
                            -
                          </>
                        }
                      </span>
                    : 
                      <span className="block font-nouvelrbold text-lg md:text-xl">{goal > 0 ? `${goal} ${targetType === 'value' ? targetPostfix : 'szt.'}` : t('objectiveAchievedOnly')}</span>
                    }
                  </p>
                  <ButtonLink anchor={t('more')} href={`/competition/${id}`} />
                </>
              }
              {type === 'details' &&
                <>
                  <p className={type === 'details' ? 'flex justify-between md:block mt-2.5 md:mt-0' : ''}>
                    <span className={`block ${type === 'details' ? 'text-base text-right text-right md:text-left' : 'font-nouvelrbold md:font-nouvelr text-sm md:text-base'}`}>{t('progressBarMissingTheTarget')}</span>
                    {id === 'filters' ?
                      <span className={`block ${type === 'details' ? 'text-right text-right md:text-left text-xl' : 'font-nouvelrbold text-lg md:text-xl'}`}>{absence && absence !== -1 ? `${absence} szt.` : '-'}</span>
                    :
                      <span className={`block ${type === 'details' ? 'text-right text-right md:text-left text-xl' : 'font-nouvelrbold text-lg md:text-xl'}`}>{absence} {targetType === 'value' ? targetPostfix : 'szt.'}</span>
                    }
                  </p>
                  <p className={type === 'details' ? 'flex justify-between md:block mt-2.5 md:mt-0' : ''}>
                    <span className={`block ${type === 'details' ? 'text-base text-right text-right md:text-left' : 'font-nouvelrbold md:font-nouvelr text-sm md:text-base'}`}>{t('progressBarObjective')}</span>
                    {id === 'filters' ?
                      <span className={`block ${type === 'details' ? 'text-right text-right md:text-left text-xl' : 'font-nouvelrbold text-lg md:text-xl'}`}>{goal} szt.</span>
                    :
                      <span className={`block ${type === 'details' ? 'text-right text-right md:text-left text-xl' : 'font-nouvelrbold text-lg md:text-xl'}`}>{goal} {targetType === 'value' ? 'zł' : 'szt.'}</span>
                    }
                  </p>
                </>
              }
            </div>
          {/*{id === 'filters' && absence !== -1 && <p className="mt-5 text-xs">{t('progressBarFiltersInfo')}</p>}*/}
        </div>
    )
}

ProgressBarItem.defaultProps = {
  absence: '',
  dates: '',
  edition: '',
  goal: '',
  id: '',
  percent: '',
  prize: '',
  result: '',
  targetType: '',
  title: '',
  type: '',
}

ProgressBarItem.propTypes = {
  absence: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dates: PropTypes.string.isRequired,
  edition: PropTypes.string,
  goal: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  prize: PropTypes.string.isRequired,
  result: PropTypes.number.isRequired,
  targetType: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default ProgressBarItem;