import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({clsWrap, cls, height, id, onClickFnc, width, viewBox}) => {
  return (
      <svg className={clsWrap} height={height} onClick={onClickFnc} width={width} viewBox={viewBox ? viewBox : '0 0 20 20'}>
        <use className={cls ? cls : ''} href={`/images/icon-map.svg#${id}`}/>
      </svg>
  );
};

Icon.defaultProps = {
  cls: '',
  clsWrap: 'icon_wrapper',
  height: '30',
  id: '',
  width: '30',
  viewBox: '0 0 20 20'
};

Icon.propTypes = {
  cls: PropTypes.string,
  clsWrap: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClickFnc: PropTypes.func,
  width: PropTypes.string,
  viewBox: PropTypes.string
};

export default Icon;
