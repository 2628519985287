import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import Icon from '../_shared/Icon';
import Loader from '../_shared/Loader';
import PopUp from '../_shared/PopUp';
import ProgressBarMini from "../_shared/ProgressBarMini";
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionSearchTableRowUsers = ({ users }) => {
  const { t } = useTranslation();

  const usersTab = users.map((user, i) => {
    return (
      <tr key={`u_${i}`} className={i === users.length - 1 ? 'border-b-2 border-solid border-grayRow' : ''}>
        <td colSpan={3} className="px-2 py-4">{user.firstName} {user.lastName}</td>
        <td colSpan={2} className="px-2 py-4">{user.phoneNumber}</td>
        <td colSpan={3} className="px-2 py-4">{user.email}</td>
        <td colSpan={2} className="px-2 py-4 text-center">{user.participate ? t('yes') : t('no')}</td>
      </tr>
    );
  })

  return (
      <>
        <tr className="bg-grayRow">
          <td colSpan={3} className="px-2 py-4 font-nouvelrbold">{t('participant')}</td>
          <td colSpan={2} className="px-2 py-4 font-nouvelrbold">{t('phone')}</td>
          <td colSpan={3} className="px-2 py-4 font-nouvelrbold">{t('email')}</td>
          <td colSpan={2} className="px-2 py-4 font-nouvelrbold">{t('competitionTakes')}</td>
        </tr>
        {usersTab}
      </>
  );
}

const CompetitionSearchTableRow = ({ ended, row, setPopUp, setReloadTable }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [grayRow, setGrayRow] = useState(false);
  const [isEditScore, setIsEditScore] = useState(false);
  const [isEditTarget, setIsEditTarget] = useState(false);
  const [loader, setLoader] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const [scoreVal, setScoreVal] = useState('');
  const [targetVal, setTargetVal] = useState('');
  const [tempTarget, setTempTarget] = useState(row.target);
  const [tempScore, setTempScore] = useState(row.score);
  const sztorzl = row.competitionId === 'filters' ? 'szt' : 'zł';
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';
  const targetMissing = `${row.targetMissing} ${row.targetType === 'value' ? sztorzl : ''}`;
  const targetPostfix = row.percentageScore ? '%' : 'zł';

  useEffect(() => {
    setTargetVal(row.target);
    setScoreVal(row.score);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.target]);

  const editScoreHandler = (close = false) => {
    setIsEditScore((prevState) => !prevState);
    setGrayRow((prevState) => !prevState);
    if (close) setScoreVal(tempScore);
  }

  const editTargetHandler = (close = false) => {
    setIsEditTarget((prevState) => !prevState);
    setGrayRow((prevState) => !prevState);
    if (close) setTargetVal(tempTarget);
  }

  const sendScoreHandler = (resultId, newValue) => {
    if (resultId && (newValue || newValue === 0)) {
      setLoader(true);

      JwtInterceptor
          .put(`${API_URL}Competitions/manage/score?resultId=${resultId}&newValue=${newValue}`)
          .then((response) => {
            setIsEditScore((prevState) => !prevState);
            setGrayRow((prevState) => !prevState);
            setTempScore(newValue);
            setReloadTable(true);
          })
          .catch(function (error) {
            setPopUp(true);
            setScoreVal(tempScore);
            if (error.response.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setLoader(false);
          });
    }
  }

  const sendTargetHandler = (resultId, newValue) => {
    if (resultId && (newValue || newValue === 0)) {
      setLoader(true);

      JwtInterceptor
          .put(`${API_URL}Competitions/manage/target?resultId=${resultId}&newValue=${newValue}`)
          .then((response) => {
            setIsEditTarget((prevState) => !prevState);
            setGrayRow((prevState) => !prevState);
            setTempTarget(newValue);
            setReloadTable(true);
          })
          .catch(function (error) {
            setPopUp(true);
            setTargetVal(tempTarget);
            if (error.response.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setLoader(false);
          });
    }
  }

  const scoreValueHandler = (e) => {
    if (e.currentTarget.value.length > 8) return;
    setScoreVal(e.currentTarget.value);
  }

  const targetValueHandler = (e) => {
    if (e.currentTarget.value.length > 8) return;
    setTargetVal(e.currentTarget.value);
  }

  const usersOpenHandler = () => {
    setUsersOpen(prevState => !prevState);
  }

  return (
      <>
        <tr className={`${grayRow ? 'bg-grayRow' : ''}`}>
          <td className="px-2 py-4 md:py-6 font-nouvelrbold align-middle">{row.competitionName}</td>
          <td className="px-2 py-4 md:py-6 align-middle">{row.serviceName}</td>
          <td className="px-2 py-4 md:py-6 align-middle">{(isEditTarget || isEditScore) ? '' : row.serviceId}</td>
          <td className="px-2 py-4 md:py-6 align-middle text-center">{(isEditTarget || isEditScore) ? '' : row.serviceNbOfAdvisors}</td>
          <td className={`px-2 py-4 md:py-6 align-middle text-right ${isEditTarget ? 'bg-white pr-0' : ''}`}>
            {ended || role === 'MOP' ?
              <>
                {row.competitionId === 'filters' && row.editionName === '2024 Etap II' ?
                  <span className="ml-1 whitespace-nowrap">{isEditScore ? '' : `${row.target} szt`}</span>
                :
                  <span className="ml-1 whitespace-nowrap">{isEditScore ? '' : `${row.target} ${row.targetType === 'value' ? targetPostfix : ''}`}</span>
                }
              </>
            :
              <form className="flex items-center whitespace-nowrap">
                <input name="target" type="number" value={targetVal} disabled={!isEditTarget} className={`inline-block w-20 outline-none text-right bg-white border-b border-solid ${isEditTarget ? 'border-formBorder' : 'border-white'}`} onChange={(e) => targetValueHandler(e)} />
                {row.targetType === 'value' ? <span className="ml-1">{targetPostfix}</span> : ''}
                {isEditTarget ?
                  <>
                    {loader ?
                      <Loader />
                    :
                      <Icon id="check" clsWrap="shrink-0 ml-3 transform transition duration-y hover:cursor-pointer hover:scale-125" onClickFnc={() => sendTargetHandler(row.resultId, targetVal)} />
                    }
                    <Icon id="x" clsWrap="shrink-0 transform transition duration-y hover:cursor-pointer hover:scale-125" onClickFnc={() => editTargetHandler(true)} />
                  </>
                :
                  <Icon id="edit" clsWrap="shrink-0 ml-3 hover:cursor-pointer" onClickFnc={editTargetHandler} />
                }
              </form>
            }
          </td>
          <td className={`px-2 py-4 md:py-6 align-middle ${isEditScore ? 'bg-white pr-0' : ''}`}>
            {ended && role === 'Admin' && row.scoreEditable ?
                <form className="flex items-center justify-end whitespace-nowrap">
                  <span className="flex flex-col items-end">
                    <span>
                      <input name="score" type="number" value={scoreVal} disabled={!isEditScore} className={`inline-block w-20 mr-1.5 outline-none text-right bg-white border-b border-solid ${isEditScore ? 'border-formBorder' : 'border-white'}`} onChange={(e) => scoreValueHandler(e)} />
                      <>
                        {row.competitionId === 'filters' && row.editionName === '2024 Etap II' ?
                          'szt'
                        :
                          row.targetType === 'value' ? targetPostfix : ''
                        }
                      </>
                    </span>
                  </span>
                  {isEditScore ?
                      <>
                        {loader ?
                            <Loader />
                            :
                            <Icon id="check" clsWrap="shrink-0 ml-3 transform transition duration-y hover:cursor-pointer hover:scale-125" onClickFnc={() => sendScoreHandler(row.resultId, scoreVal)} />
                        }
                        <Icon id="x" clsWrap="shrink-0 transform transition duration-y hover:cursor-pointer hover:scale-125" onClickFnc={() => editScoreHandler(true)} />
                      </>
                      :
                      <Icon id="edit" clsWrap="shrink-0 ml-3 hover:cursor-pointer" onClickFnc={editScoreHandler} />
                  }
                </form>
            :
              <span className="flex items-center justify-end whitespace-nowrap">
                {isEditTarget ? '' :
                  <>
                    {row.score} {row.targetType === 'value' ? targetPostfix : ''}
                    <ProgressBarMini percent={row.scorePercentage} />
                  </>
                }
              </span>
            }
          </td>
          <td className="px-2 py-4 md:py-6 font-nouvelrbold align-middle text-right">
            {(isEditTarget || isEditScore) ? '' : `${row.scorePercentage} %`}
          </td>
          <td className="px-2 py-4 md:py-6 font-nouvelrbold align-middle text-right">
            {(isEditTarget || isEditScore) ? '' :
              <>
                {row.scorePercentage >= 100 ?
                  t('objectiveAchieved')
                  :
                  <>
                    {(targetMissing !== '-1 zł' && targetMissing !== '-1 szt') ? targetMissing : '-'}
                  </>
                }
              </>
            }
          </td>
          <td className="px-2 py-4 md:py-6 align-middle text-right">{(isEditTarget || isEditScore) ? '' : row.prize+' zł'}</td>
          <td className="px-2 py-4 md:py-6 align-middle">
            {(isEditTarget || isEditScore) ? '' : <Icon id="upArr" clsWrap={`hover:cursor-pointer ${usersOpen ? '' : 'rotate-180 '}`} onClickFnc={usersOpenHandler} />}
          </td>
        </tr>
        {row.serviceUsers && row.serviceUsers.length > 0 && usersOpen &&
          <CompetitionSearchTableRowUsers users={row.serviceUsers} />
        }
      </>
  )
}

const CompetitionSearchTable = ({ competitions, ended, setReloadTable }) => {
  const { t } = useTranslation();
  const [popUp, setPopUp] = useState(false);
  const rows = competitions ? competitions.map(row => <CompetitionSearchTableRow ended={ended} row={row} setPopUp={setPopUp} setReloadTable={setReloadTable} key={`c_${row.resultId}`} />) : '';

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <div className="overflow-x-scroll md:overflow-x-hidden">
        <table className="w-full">
          <thead className="bg-black font-nouvelrbold text-white text-base">
            <tr>
              <td className="px-2 py-4 align-middle">{t('competitionName')}</td>
              <td className="px-2 py-4 align-middle">{t('serviceName')}</td>
              <td className="px-2 py-4 align-middle text-center">{t('birNumber')}</td>
              <td className="px-2 py-4 align-middle text-center">{t('employeesNumber')}</td>
              <td className="px-2 py-4 align-middle text-center">{t('progressBarObjective')}</td>
              <td className="px-2 py-4 align-middle text-center">{t('progressBarActualResult')}</td>
              <td className="px-2 py-4 align-middle text-right">{t('realization')}</td>
              <td className="px-2 py-4 align-middle text-right">{t('progressBarMissingTheTarget')}</td>
              <td className="px-2 py-4 align-middle text-right">{t('potentialAwardForBir')}</td>
              <td className="px-2 py-4 align-middle"></td>
            </tr>
          </thead>
          {rows.length > 0 &&
            <tbody className="text-base">
              {rows}
            </tbody>
          }
        </table>
        <PopUp open={popUp} text={t('errDefault')} type="error" hideFnc={popUpCloseHandler} />
      </div>
  );
}

export default CompetitionSearchTable;