import React from 'react';
import Menu from "../Menu";
import PageTitle from "../_shared/PageTitle";

const Cookies = () => {
  return (
      <div>
        <Menu />
        <div className="text-base">
          <PageTitle text="Polityka Cookies" />
          <p className="mt-5">W związku z udostępnianiem zawartości serwisu www.rcserviceclub.pl (dalej: Serwis), Motivation Direct Sp. z o.o. z siedzibą w Warszawie przy ul. Jana Czeczota 31, 02-607 Warszawa, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000358484, posiadającą nr NIP 521-356-87-96 oraz nr REGON 142450530, o kapitale zakładowym w wysokości 50.000 zł (dalej: Administrator) informuje, że używa na stronach www technologii cookies, tj. plików tekstowych, tzw. ciasteczek, do rejestrowania informacji o stronach lub podstronach odwiedzonych przez Użytkownika oraz jego aktywności.</p>
          <p className="mt-5">Informacje zapisywane są przez serwery na urządzeniu końcowym Użytkownika, które serwery mogą odczytać przy każdorazowym połączeniu się z tego urządzenia końcowego, może także używać innych technologii o funkcjach podobnych lub tożsamych z cookies. W niniejszym dokumencie, informacje dotyczące cookies mają zastosowanie również do innych podobnych technologii stosowanych w ramach naszych serwisów internetowych. Pliki cookies zazwyczaj zawierają nazwę domeny serwisu internetowego, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
          <p className="mt-5">Niniejsza polityka dotyczy Użytkowników, o których mowa w Regulaminie Serwisu.</p>
          <p className="mt-5">Warunkiem korzystania z plików cookies przez Administratora jest ich akceptacja przez Użytkownika.</p>
          <p className="mt-5">Pliki cookies wykorzystywane są w celu:<br/>
            a) dostosowania zawartości stron serwisów internetowych do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych, w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika serwisu internetowego i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;<br/>
            b)tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;<br />
            c)utrzymania sesji Użytkownika serwisu internetowego (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie serwisu ponownie wpisywać loginu i hasła;<br />
            d)dostarczania użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.<br/>
            e) zapamiętania ustawień Użytkownika;
          </p>
          <p className="mt-5">W ramach Serwisu mogą być stosowane:<br />
            a) niezbędne pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu internetowego, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;<br/>
            b) pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;<br />
            c) pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;<br/>
            d) funkcjonalne pliki cookies, umożliwiające zapamiętanie wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;<br/>
            e) reklamowe pliki cookies, umożliwiające dostarczanie użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.
          </p>
          <p className="mt-5">W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika.</p>
          <p className="mt-5">Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu użytkownika Serwisu internetowego. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej). Niedokonanie zmiany ustawień w zakresie cookies oznacza, że będą one zamieszczone w urządzeniu końcowym użytkownika, a tym samym będą przechowywane informacje w urządzeniu końcowym użytkownika i Administrator będzie miał dostęp do tych informacji.</p>
          <p className="mt-5">Użytkownik może dobrowolnie usunąć pliki cookies w przeglądarce internetowej, której używa.</p>
          <p className="mt-5">Wyłączenie lub ograniczenie stosowania plików cookies może spowodować utrudnienia korzystanie z niektórych usług Serwisu, w szczególności wymagających logowania. Wyłączenie opcji przyjmowania cookies nie powoduje natomiast braku możliwości czytania lub oglądania treści zamieszczanych w Serwisie z zastrzeżeniem tych, do których dostęp wymaga logowania.</p>
          <p className="mt-5">Pliki cookies mogą być zamieszczane w urządzeniu końcowym Użytkownika Serwisu, a następnie wykorzystywane przez współpracujących z  Administratorem reklamodawców, przez firmy badawcze oraz dostawców aplikacji multimedialnych.</p>
          <p className="mt-5">Nie jest możliwe przedostanie się wirusów, niechcianego oprogramowania lub oprogramowania złośliwego do urządzeń Użytkownika poprzez pliki cookies.</p>
          <p className="mt-5">Cookies nie zawierają danych osobowych i nie pozwalają na identyfikację osoby.</p>
          <p className="mt-5">Administrator korzysta z trzech rodzajów plików cookies:<br/>
            a) Cookies sesyjne - pliki przechowywane na urządzeniu Użytkownika i pozostające tam do momentu zakończenia sesji danej przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci Urządzenia. Mechanizm cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych, ani żadnych informacji poufnych z urządzenia Użytkownika.<br/>
            b) Cookies trwałe - pliki przechowywane na urządzeniu Użytkownika i pozostające tam do momentu ich usunięcia przez Użytkownika. Zakończenie sesji danej przeglądarki lub wyłączenie urządzenia nie powoduje ich usunięcia z urządzenia Użytkownika. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych, ani żadnych informacji poufnych z urządzenia Użytkownika.<br/>
            c) Cookies firm zewnętrznych - m.in. google, facebook, twitter. Informacje dotyczące polityki cookies firm zewnętrznych dostępne są na witrynie danej firmy.
          </p>
          <p className="mt-5">Google Analytics – administrator: Google Inc z siedzibą w USA<br/>
            Plus.google.com – administrator: Google Inc z siedzibą w USA<br/>
            Facebook.com – administrator: Facebook Inc z siedzibą w USA oraz Facebook Ireland z siedzibą w Irlandii<br/>
            Twitter.com – administrator: Twitter, Inc z siedzibą w USA<br/>
            Tokbox.com – administrator: Telefónica Digital z siedzibą w USA<br/>
            Paymanto – administrator: Paymento S.A. z siedzibą w Polsce<br/>
          </p>
        </div>
      </div>
  )
}

export default Cookies;