import React from 'react';
import { useTranslation } from 'react-i18next';
import { reverseDate } from '../../commons/functions';
import Button from "../_shared/Button";

const CompetitionManagementBoxFutureItem = ({ item, competitionHandler }) => {
  const { t } = useTranslation();

  return (
      <div className="flex flex-col xs:flex-row xs:justify-between items-center mt-4 text-base">
        <div className="xs:w-8/12">
          <span className="sm:flex sm:justify-between">
            <span className="block sm:w-1/2 font-nouvelrbold">{item.name}</span>
            <span className="block sm:w-1/2">
              <span className="text-nowrap">{reverseDate(item.dateFrom)}</span> -&nbsp;
              <span className="text-nowrap">{reverseDate(item.dateTo)}</span>
            </span>
          </span>
          {item.booster &&
            <span className="sm:flex sm:justify-between text-sm">
              <span className="block sm:w-1/2">{t('booster')}</span>
              <span className="block sm:w-1/2">
                <span className="text-nowrap">{reverseDate(item.booster.dateFrom)}</span> -&nbsp;
                <span className="text-nowrap">{reverseDate(item.booster.dateTo)}</span>
              </span>
            </span>
          }
        </div>
        <div  className="xs:w-4/12 mt-5 xs:mt-0">
          <Button anchor={t('edit')} icon="edit" onClickFnc={() => competitionHandler(`/competition-management-edit/${item.id}`)} styles="w-fit float-right" />
        </div>
      </div>
  )
}

const CompetitionManagementBoxFuture = ({ future, competitionHandler }) => {
  const { t } = useTranslation();

  return (
      <div className="pt-6 pb-6 border-solid border-t border-grayBorder">
        <p className="text-xl text-center xs:text-left">{t('stageUpcoming')}</p>
        {future.map(item => <CompetitionManagementBoxFutureItem item={item} key={item.id}  competitionHandler={competitionHandler} />)}
      </div>
  );
}

export default CompetitionManagementBoxFuture;