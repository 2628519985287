import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reverseDate } from "../../commons/functions";
import Icon from '../_shared/Icon';

const CompetitionItem = ({ editionData }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let zlszt = (editionData.competitionId === 'filters' ? '%' : 'zł');

  if (editionData.editionName === "2024 Etap II") zlszt = 'szt';
  const openHandler = () => {
    setOpen(prevState => !prevState);
  }

  return (
      <div>
        <div className={`relative lg:flex lg:items-center lg:justify-between mb-6 md:mb-4 text-lg text-xl ${open ? 'font-nouvelrbold md:font-nouvelr' : ''}`}>
          <span className="block lg:inline">{editionData.editionName}</span>
          <div className="flex justify-between">
            <span>{reverseDate(editionData.dateFrom)} — {reverseDate(editionData.dateTo)}</span>
            <Icon id="upArr" viewBox="0 0 32 32" width="32" height="32" clsWrap={`absolute top-0 right-0 md:relative hover:cursor-pointer ${open ? '' : 'rotate-180'}`} onClickFnc={() => openHandler()} />
          </div>
        </div>
        <div className={`items-center justify-between mb-10 ${open ? 'block md:flex' : 'hidden'}`}>
          <div className="flex item-center justify-between md:block">
            <span className="block text-base">{t('progressBarObjective')}</span>
            <span className="block text-xl">{editionData.target} {editionData.targetType === 'value' ? zlszt : 'szt.'}</span>
          </div>
          <div className="flex item-center justify-between md:block mt-3 md:mt-0">
            <span className="block text-base">{t('progressBarResultAchieved')}</span>
            <span className="block text-xl">{editionData.score} {editionData.targetType === 'value' ? zlszt : 'szt.'}</span>
          </div>
          <div className="flex item-center justify-between md:block mt-3 md:mt-0">
            <span className="block text-base">{t('prize')}</span>
            <span className="block text-xl">{editionData.prize ? editionData.prize + ' zł' : '-'}</span>
          </div>
        </div>
      </div>
  )
}

const CompetitionPrev = ({ prevEdition }) => {
  const { t } = useTranslation();
  let editions;

  if (prevEdition) {
      editions = prevEdition.map(edition => <CompetitionItem editionData={edition} key={edition.editionId} />);
  }

  return (
      <div className="md:relative md:z-10 md:w-2/5 md:pr-12 md:mt-m190px md:bg-white">
        {prevEdition && prevEdition.length > 0 &&
          <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('previousEditions')}</h3>
        }
        {editions}
      </div>
  )
}

export default CompetitionPrev;