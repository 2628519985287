import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Context } from '../../commons/context';
import EditTitle from '../_shared/EditTitle';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const ProfileData = () => {
  const { t } = useTranslation();
  const [data, setData] = useContext(Context);
  const [isEdit, setIsEdit] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const formData = new FormData();

  const onSubmit = async (sendData) => {
    if (!loader) {
      setLoader(true);

      formData.append('Email', data.user.email);
      formData.append('FirstName', sendData.FirstName);
      formData.append('ImageFile', sendData.ImageFile[0]);
      formData.append('LastName', sendData.LastName);
      formData.append('PhoneNumber', sendData.PhoneNumber);

      JwtInterceptor
          .post(`${API_URL}Users/update`, formData, {headers: {'Content-Type': 'multipart/form-data' }})
          .then((response) => {
            setPopUpText(t('dataChangeSuccess'));
            setPopUpType('success');

            setData({
              type: 'SET_USER',
              payload: {
                ...data.user,
                email: response.data.email,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                phoneNumber: response.data.phoneNumber,
                image: response.data.image,
              },
            });
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUpType('error');
          }).finally(() => {
            setLoader(false);
            setPopUp(true);
            setIsEdit(false);
          });
    }
  };

  const editHandler = (e) => {
    e.preventDefault();
    setIsEdit(prevState => !prevState);
    reset();
  }

  const popUpCloseHandler = () => {
    setPopUp(false);
  }
console.log(data.user);
  return (
    <div>
      <EditTitle editLinkText={t('edit')} text={t('myData')} editFnc={(e) => editHandler(e)} isEdit={isEdit} isLoading={loader} sendFnc={handleSubmit(onSubmit)} />
      <form className="w-full mb-10">
        <p className="flex text-base w-full">
          <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('nameContactForm')}</span>
          <input type="text" {...register("FirstName", { required: true, disabled: !isEdit, maxLength: 40, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} defaultValue={data.user.firstName} maxLength="40" className={`inline-block w-6/12 md:w-8/12 outline-none bg-white border-b border-solid ${isEdit ? 'border-formBorder' : 'border-white'}`} />
        </p>
        {errors.FirstName && errors.FirstName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
        {errors.FirstName && errors.FirstName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
        <p className="flex text-base mt-4">
          <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('surname')}</span>
          <input type="text" {...register("LastName", { required: true, disabled: !isEdit, maxLength: 50, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} defaultValue={data.user.lastName} maxLength="50" className={`inline-block w-6/12 md:w-8/12 outline-none bg-white border-b border-solid ${isEdit ? 'border-formBorder' : 'border-white'}`} />
        </p>
        {errors.LastName && errors.LastName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
        {errors.LastName && errors.LastName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
        <p className="flex text-base mt-4">
          <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('userName')}</span>
          {/*<input type="text" {...register("Email", { required: true, disabled: !isEdit, maxLength: 80, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z-]+)+$/i})} defaultValue={data.user.email} maxLength="80" className={`inline-block w-6/12 md:w-8/12 outline-none bg-white border-b border-solid ${isEdit ? 'border-formBorder' : 'border-white'}`} />*/}
          <span>{data.user.userName} {isEdit && <span>*</span>}</span>
        </p>
        <p className="flex text-base mt-4">
          <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('email')}</span>
          {/*<input type="text" {...register("Email", { required: true, disabled: !isEdit, maxLength: 80, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z-]+)+$/i})} defaultValue={data.user.email} maxLength="80" className={`inline-block w-6/12 md:w-8/12 outline-none bg-white border-b border-solid ${isEdit ? 'border-formBorder' : 'border-white'}`} />*/}
          <span>{data.user.email} {isEdit && <span>*</span>}</span>
        </p>
        {/*{errors.Email && errors.Email.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}*/}
        {/*{errors.Email && errors.Email.type === "pattern" && <span className="text-error">{t('errFieldEmail')}</span>}*/}
        <p className="flex text-base mt-4">
          <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('phone')}</span>
          <input type="text" {...register("PhoneNumber", { disabled: !isEdit, maxLength: 9, pattern: /^[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{3}$/i})} defaultValue={data.user.phoneNumber} maxLength="9" className={`inline-block w-6/12 md:w-8/12 outline-none bg-white border-b border-solid ${isEdit ? 'border-formBorder' : 'border-white'}`} />
        </p>
        {errors.PhoneNumber && errors.PhoneNumber.type === "pattern" && <span className="text-error">{t('errFieldFormat')}</span>}
        <p className="flex text-base mt-4">
          <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('imageFile')}</span>
          <input type="file" {...register("ImageFile", { disabled: !isEdit })} defaultValue="" className={`inline-block w-6/12 md:w-8/12 outline-none bg-transparent`} />
        </p>
      </form>
      {isEdit && <span className="block text-xs mb-5" dangerouslySetInnerHTML={{__html: t(`emailDisabledInfo`) }} />}
      <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
    </div>
  );
}

export default ProfileData;