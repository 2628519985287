import React from 'react';
import { useTranslation } from "react-i18next";
import ContactForm from "./ContactForm";
import Menu from '../Menu';
import PageTitle from '../_shared/PageTitle';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <Menu />
      <PageTitle text="Kontakt" />
      <div className="md:flex">
        <img src="/images/contactt.png" alt="contact" className="h-fit w-1/2" />
        <div className="mt-10 md:mt-0 md:ml-5">
          <p className="text-16px">{t('contactText')}</p>
          <ContactForm />
        </div>
      </div>
    </>
  );
}

export default Contact;