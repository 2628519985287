import React from 'react';
import PropTypes from "prop-types";

const PageTitle = ({ text }) => {
  return (
      <h3 className="mb-8 md:mb-6 mt-8 text-xl md:text-30px font-nouvelrbold">{text}</h3>
  )
}

PageTitle.defaultProps = {
  text: ''
};

PageTitle.propTypes = {
  text: PropTypes.string.isRequired
};

export default PageTitle;