import React from 'react';
import { useTranslation } from "react-i18next";
import DownloadLink from "../_shared/DownloadLink";
import EditTitle from '../_shared/EditTitle';
import Icon from '../_shared/Icon';

const ProfileCard = ({ data }) => {
  const { t } = useTranslation();


  return (
      <div>
        <EditTitle text={t('myCard')} />
        <img className="max-h-40 mb-5" src="/images/cardImg.png" alt="card" />
        <div className="w-full mb-10">
          <p className="flex text-base w-full">
            <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('cardNumber')}</span>
            <span className="inline-block w-6/12 md:w-8/12">{data.cardNumber ? data.cardNumber : '-'}</span>
          </p>
          {data.cardBalance &&
            <p className="flex text-base mt-4">
              <span className="inline-block font-nouvelrbold w-6/12 md:w-4/12">{t('cardBalance')}</span>
              <span className="inline-block w-6/12 md:w-8/12">{data.cardBalance} zł</span>
            </p>
          }
          <p className="py-4">
            <DownloadLink text={t('cardRulesDownloadAnchor')}  link="/pdf/eprl_regulamin_uzytkowania_przedplaconej_karty_edenred_11_03_2024_PL.pdf" />
          </p>
          <p>
            <DownloadLink text={t('howCartActivate')} link="/pdf/card_neut_std_2_druk.pdf" />
          </p>
          <p className="mt-5 mb-5">
            <a href="https://nowy.myedenred.pl/" rel="noreferrer" target="_blank" className="flex items-center font-semibold transition ease-in-out duration-300 stroke-black hover:text-brand">
              {t('checkCardStatus')}
              <Icon id="leftArr" viewBox="0 0 22 21" width="22" height="21" clsWrap="ml-4 rotate-180	" />
            </a>
          </p>
        </div>
      </div>
  );
}

export default ProfileCard;