import React from 'react';
import { useTranslation } from 'react-i18next';
import { reverseDate } from '../../commons/functions';
import Button from "../_shared/Button";

const CompetitionManagementBoxCurrent = ({ current, competitionHandler }) => {
  const { t } = useTranslation();

  return (
      <div className="pb-6 pt-6 border-t border-grayBorder border-solid">
        <p className="text-xl text-center xs:text-left">{t('stageCurrent')}</p>
        <div className="flex flex-col xs:flex-row xs:justify-between items-center mt-4 text-base">
          <div className="xs:w-8/12">
            <span className="sm:flex sm:justify-between">
              <span className="block font-nouvelrbold sm:w-1/2">{current.name}</span>
              <span className="block sm:w-1/2">
                <span className="text-nowrap">{reverseDate(current.dateFrom)}</span> -&nbsp;
                <span className="text-nowrap">{reverseDate(current.dateTo)}</span>
              </span>
            </span>
            {current.booster &&
              <span className="sm:flex sm:justify-between text-sm">
                <span className="block sm:w-1/2">{t('booster')}</span>
                <span className="block sm:w-1/2">
                  <span className="text-nowrap">{reverseDate(current.booster.dateFrom)}</span> -&nbsp;
                  <span className="text-nowrap">{reverseDate(current.booster.dateTo)}</span>
                </span>
              </span>
            }
          </div>
          <div className="xs:w-4/12 mt-5 xs:mt-0">
            <Button anchor={t('edit')} icon="edit" onClickFnc={() => competitionHandler(`/competition-management-edit/${current.id}`)} styles="inline-block w-fit float-right" />
          </div>
        </div>
      </div>
  );
}

export default CompetitionManagementBoxCurrent;