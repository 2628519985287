import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonLink from '../_shared/ButtonLink';
import Menu from '../Menu';
import PageTitle from './../_shared/PageTitle';
import TeamTable from './TeamTable';

const Team = () => {
  const { t } = useTranslation();

  return (
      <>
        <Menu />
        <div className="flex items-center justify-between">
          <PageTitle text={t('myTeam')} />
          <ButtonLink anchor={t('addUser')} href="/add-user" icon="plusThin" />
        </div>
        <TeamTable />
      </>
  )
}

export default Team;