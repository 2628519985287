import React from 'react';
import { useTranslation } from "react-i18next";

const InformationsHalfBanner = () => {
  const { t } = useTranslation();

  return (
      <section className="mt-120px md:flex md:items-center">
        <div className="text-left md:order-2 md:pl-60px">
          <h3 className="font-nouvelrbold text-xl md:text-30px mb-6">{t('informationBottomBannerTitle')}</h3>
          <p className="font-nouvelrbold text-lg md:text-xl mb-6 md:mb-20">{t('informationBottomBannerSubtitle')}</p>
          <p className="text-sm md:text-base" dangerouslySetInnerHTML={{__html: t('informationBottomBannerText') }} />
        </div>
        <img className="mt-10 md:mt-0 md:order-1" src="/images/car.png" alt="car" />
      </section>
  )
}

export default InformationsHalfBanner;