import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Loader from '../_shared/Loader';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from '../_shared/JwtInterceptors';

const API_URL = process.env.REACT_APP_API_URL;

const ContactForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [textareaVal, setTextareaVal] = useState(false);
  const message = watch("message");

  useEffect(() => {
    setTextareaVal(message);
  }, [message]);

  const onSubmit = data => {
    if (loader) return;

    setLoader(true);

    JwtInterceptor
        .post(`${API_URL}Contact`, data)
        .then(() => {
          setPopUpText(t('contactFormSuccess'));
          setPopUpType('success');
          reset();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
        }).finally(() => {
          setLoader(false);
          setPopUp(true);
    });
  }

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <>
        <form className="flex flex-col mt-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mt-4 float-label-input">
            <input type="text" id="firstName" placeholder=" " {...register("firstName", { required: true, maxLength: 40, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} maxLength="40" className="block w-full lg:w-1/2 py-1.5 outline-none border-b border-solid border-grayBorder" />
            <label htmlFor="firstName" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('nameContactForm')} *</label>
            {errors.firstName && errors.firstName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.firstName && errors.firstName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <div className="relative mt-8 float-label-input">
            <input type="text" id="lastName" placeholder=" " {...register("lastName", { required: true, maxLength: 50, pattern: /^[^\s]+[^^<>&]{2,50}$/i})} maxLength="50" className="block w-full lg:w-1/2 py-1.5 outline-none border-b border-solid border-grayBorder" />
            <label htmlFor="lastName" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('surname')} *</label>
            {errors.lastName && errors.lastName.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.lastName && errors.lastName.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <div className="relative mt-8 float-label-input">
            <input type="text" id="email" placeholder=" " {...register("email", { required: true, maxLength: 80, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z-]+)+$/i})} maxLength="80" className="block w-full lg:w-1/2 py-1.5 outline-none border-b border-solid border-grayBorder" />
            <label htmlFor="email" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('emailAdress')} *</label>
            {errors.email && errors.email.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.email && errors.email.type === "pattern" && <span className="text-error">{t('errFieldEmail')}</span>}
          </div>
          <div className={`relative mt-8 float-label-input ${textareaVal ? 'isValue' : ''}`}>
            <textarea id="message" placeholder=" " {...register("message", { required: t('errFieldRequired'), maxLength: 2000, pattern: /^[^\s]+[^^<>&]{2,2000}$/i})} maxLength="2000" className="block w-full h-44 py-1.5 outline-none border-b border-solid border-grayBorder" />
            <label htmlFor="message" className="absolute top-1.5 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white text-formBorder">{t('question')} *</label>
            {errors.message && errors.message.type === "required" && <span className="text-error">{t('errFieldRequired')}</span>}
            {errors.message && errors.message.type === "pattern" && <span className="text-error">{t('errFieldValue')}</span>}
          </div>
          <button type="submit" className={`relative w-fit mt-8 p-4 border font-nouvelrbold text-black text-basee transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand ${loader ? 'pr-12' : ''}`}>
            {t('send')}
            {loader && <Loader />}
          </button>
        </form>
        <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
      </>
  );
}

export default ContactForm;