import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Button = ({ anchor, loading, icon, iconLeft, iconStyles, onClickFnc, styles }) => {
  return (
      <div className={`relative max-h-46px px-4 py-15px font-nouvelrbold leading-4 border border-black border-solid transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand whitespace-nowrap ${icon ? 'pr-14' : ''} ${iconLeft ? 'pl-14' : ''} ${styles}`} onClick={onClickFnc} >
        {iconLeft &&
          <Icon id={loading ? 'loader' : iconLeft} viewBox={loading ? '0 0 20 20' : '0 0 32 32'} width={loading ? '20' : '32'} height={loading ? '20' : '32'} clsWrap={`absolute ${loading ? 'top-3 left-4 animate-spin' : 'top-1/2 left-4 transform -translate-y-1/2'} ${iconStyles}`} />
        }
        <span className="relative -top-0.5">{anchor}</span>
        {icon &&
          <Icon id={loading ? 'loader' : icon} viewBox={loading ? '0 0 20 20' : '0 0 32 32'} width={loading ? '20' : '32'} height={loading ? '20' : '32'} clsWrap={`absolute ${loading ? 'top-3 right-4 animate-spin' : 'top-1/2 right-4 transform -translate-y-1/2'} ${iconStyles}`} />
        }
      </div>
  )
}

Button.defaultProps = {
  anchor: '',
  loading: false,
  icon: false,
  iconLeft: false,
  iconStyles: '',
  styles: ''
}

Button.propTypes = {
  anchor: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconStyles: PropTypes.string,
  onClickFnc: PropTypes.func,
  styles: PropTypes.string,
}


export default Button;