import React from 'react';
import PropTypes from 'prop-types';
import CompetitionManagementEditForm from "./CompetitionManagementEditForm";
const CompetitionManagementEditTitleBox = ({ image, title, stageData, urlId }) => {
  return (
    <div>
      <img className="md:absolute" src={image} alt="car"/>
      <div className="md:flex">
        <div className="md:w-2/5"></div>
        <div className="md:realtive md:z-10 md:w-3/5">
          <div className="md:min-h-56 mx-m24px md:mx-0 mt-m24px md:mt-32 px-5 py-5 pt-12 pb-8 md:p-10 bg-lightGrayBg md:rounded font-nouvelrbold text-3xl md:text-56px">
            {title}
          </div>
          <CompetitionManagementEditForm stageData={stageData} urlId={urlId} />
        </div>
      </div>
    </div>
  );
}

CompetitionManagementEditTitleBox.defaultProps = {
  image: '',
  title: ''
}

CompetitionManagementEditTitleBox.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CompetitionManagementEditTitleBox;