import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '../_shared/Button';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionSearchRaports = ({ clearFilters, filterParams }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';

  const getRaportHandler = (btn) => {
    let getLink = '';

    if (btn === 1) {
      getLink = 'Competitions/competitionsReport';
      setLoading(true);
    } else {
      getLink = 'Competitions/usersReport'
      setLoadingSecond(true);
    }

    JwtInterceptor
        .get(`${API_URL}${getLink}${filterParams}`, {responseType: 'blob'})
        .then((result) => {
          const href = URL.createObjectURL(result.data);
          const link = document.createElement('a');

          link.href = href;
          link.setAttribute('download', 'Report-export.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoadingSecond(false);
        });
  }

  return (
      <section className="flex flex-col md:flex-row items-end md:items-start md:justify-between my-8">
        <Button anchor={t('clearFilters')} onClickFnc={clearFilters} styles="w-fit mb-12 md:mb-0" />
        {role === 'Admin' &&
          <div className="flex flex-col md:flex-row justify-end">
            <Button anchor={t('generateCompetitionReport')} icon="download" loading={loading} styles="mb-4" onClickFnc={() => getRaportHandler(1)} />
            <Button anchor={t('generateParticipantReport')} icon="download" loading={loadingSecond} styles="md:ml-4" onClickFnc={() => getRaportHandler(2)} />
          </div>
        }
      </section>
  );
}

export default CompetitionSearchRaports;