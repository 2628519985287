import React from 'react';
import Menu from "../Menu";
import PageTitle from "../_shared/PageTitle";

const InformationFiding = () => {
  return (
      <div>
        <Menu />
        <div className="text-base">
          <PageTitle text="Klauzula Informacyjna" />
          <p className="mt-5">Osoby fizyczne informujemy, iż zastosowanie wobec nich w zakresie danych osobowych znajdują zastosowanie przepisy Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (zwanego dalej „RODO”).</p>
          <p className="mt-5">W związku z powyższym i w oparciu o obowiązujące przepisy dotyczące ochrony danych osobowych, wypełniając obowiązki wynikające z przepisów RODO i traktując priorytetowo w procesach biznesowych kwestię ochrony przetwarzanych danych osobowych informujemy, iż Administratorem Pani/Pana danych osobowych jest <b className="font-nouvelrbold">Renault Polska Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, ul. Marynarska 13, 02 – 674 Warszawa, wpisana do Krajowego Rejestru Sądowego – Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy KRS pod numerem 0000121892, kapitał zakładowy w wysokości 4 773 000,00 zł, REGON: 012580005, NIP: 5260205983,</b>  oraz Autoryzowani Partnerzy Renault,  których pracownikami lub współpracownikami Państwo jesteście, a także Renault SAS z siedzibą we Francji pod adresem 122-122 bis avenue du Général Leclerc – 92 100 Boulogne-Billancourt, działający razem jako współadministratorzy, zwani łącznie „Administratorami”.</p>
          <p className="mt-5">W razie pytań związanych z przetwarzaniem danych osobowych, zachęcamy do kontaktu z powołanym przez Renault Polska sp. z o .o. Inspektorem Ochrony Danych pod adresem e-mail <a href="mailto:renault.iod@renault.com.pl" className="underline">renault.iod@renault.com.pl</a>.</p>
          <p className="mt-5">Wszelkie wyrażenia pisane wielką literą, jak i inne terminy zawarte i wykorzystane w  niniejszej klauzuli informacyjnej, mają przede wszystkim znaczenie przypisane im poniżej, a w razie braku definicji– znaczenie przypisane im w Regulaminie, dostępnym pod adresem: <a className="underline" href="https://rserviceclub.com/pdf/regulamin.pdf" target="_blank" rel="noreferrer">https://rserviceclub.com/pdf/regulamin.pdf</a>.</p>
          <p className="mt-5">Pani/Pana dane osobowe będą przetwarzane na podstawie:
            <ul>
              <li>a)	art. 6 ustęp 1 litra a RODO -  w celu korzystania z funkcjonalności plików cookies fakultatywnych, gdy Użytkownik wyrazi zgodę na ich włączenie, </li>
              <li>b)	art. 6 ustęp 1 litera b RODO – w celu zawarcia i realizacji umowy świadczenia usług drogą elektroniczną, która obejmuje założenie i korzystanie z Konta oraz korzystanie z funkcjonalności portalu R Service Club, </li>
              <li>c)	art. 6 ustęp 1 litera c RODO – w celu w celu wypełnienia przez Administratorów ciążących na nich obowiązków prawnych, (np. rozpatrywanie reklamacji, dokonania rozliczeń księgowo-rachunkowych, realizacji praw z RODO),</li>
              <li>d)	art. 6 ustęp 1 litera f RODO - w uzasadnionym interesie Administratorów, tj. w celu odpowiadania przez Administratorów na zapytania, wezwania bądź wnioski Użytkowników bądź zgłoszenia naruszeń praw lub dóbr oraz w celach ewentualnej obrony lud dochodzenia roszczeń oraz wykonywania marketingu bezpośredniego, a także w celu umożliwienia korzystania z Serwisu, tj. bezbłędnej prezentacji i optymalizacji Serwisu (pliki cookies obligatoryjne).</li>
            </ul>
          </p>
          <p className="mt-5">Informujemy, iż odbiorcą Pani/Pana danych osobowych będą podmioty, którym Administratorzy powierzą dane wyłącznie w zakresie niezbędnym do realizacji celu, w jakim zostały przekazane – na podstawie stosownych umów o powierzenie przetwarzania danych, w tym podmiotom świadczącym następujące usługi: informatyczne, zarządzania tożsamością, hostingu, analizy danych, tworzenia kopii zapasowych, bezpieczeństwa, prawne, obsługi klientów, księgowe oraz przechowywania danych. Dane mogą być także udostępniane innym Autoryzowanym Partnerom działającym w ramach grupy Renault Polska, z których listą można zapoznać się na stronach internetowych: www.renault.pl, www.dacia.pl i www.alpinecars.com. </p>
          <p className="mt-5">Pani/Pana dane będą przetwarzane w okresie korzystania z Serwisu, do momentu przedawnienia ewentualnych roszczeń, a także przez czas wymagany przez obowiązujące przepisy prawa lub do czasu wykonania obowiązków prawnych, nie dłużej niż przez czas w jakim Administratorzy mogą ponieść konsekwencje prawne niewykonania takiego obowiązku. W razie przetwarzania danych osobowych w oparciu o zgodę, Pani/Pana dane będą wykorzystywane do momentu wycofania zgody. Administratorzy przestaną wcześniej przetwarzać dane w uzasadnionych interesach Administratorów, w szczególnej sytuacji Pani/Pana sytuacji, jeżeli zgłosi Pani/Pan sprzeciw wobec przetwarzania danych w tych celach. W przypadku przetwarzania danych osobowych w celu marketingowym, Pani/Pana dane będą przechowywane do momentu wycofania przez Panią/Pana zgody lub zgłoszenia sprzeciwu dla przetwarzania danych w celu marketingowym.</p>
          <p className="mt-5">Informujemy, iż przysługuje Pani/Panu prawo do:
            <ul>
              <li>a)	dostępu do treści swoich danych, w tym żądania kopii danych, </li>
              <li>b)	sprostowania nieprawidłowych danych oraz żądania uzupełnienia niekompletnych danych,</li>
              <li>c)	usunięcia danych („prawo do bycia zapomnianym”), jeśli zachodzi jedna z następujących okoliczności:
                <ul className="pl-5">
                  <li>a.	dane osobowe nie są już niezbędne do celów, w których zostały zebrane lub w inny sposób przetwarzane; </li>
                  <li>b.	osoba, której dane dotyczą, cofnęła zgodę, na której opiera się przetwarzanie i nie ma innej podstawy prawnej przetwarzania; </li>
                  <li>c.	osoba, której dane dotyczą, wnosi sprzeciw na mocy art. 21 ust. 1 (w związku z przetwarzaniem danych na podstawie zadania realizowanego w interesie publicznym lub w ramach sprawowania władzy publicznej przez Administratora) i nie występują nadrzędne prawnie uzasadnione podstawy przetwarzania lub osoba, której dane dotyczą, wnosi sprzeciw na mocy art. 21 ust. 2 wobec przetwarzania (w związku z przetwarzaniem danych na potrzeby marketingu bezpośredniego); </li>
                  <li>d.	dane osobowe były przetwarzane niezgodnie z prawem; </li>
                  <li>e.	dane osobowe muszą zostać usunięte w celu wywiązania się z obowiązku prawnego przewidzianego w prawie Unii lub prawie państwa członkowskiego, któremu podlega Administrator; </li>
                  <li>f.	dane osobowe zostały zebrane w związku z oferowaniem bezpośrednio dzieciom usług społeczeństwa informacyjnego.</li>
                </ul>
              </li>
              <li>d)	ograniczenia przetwarzania, w następujących przypadkach:
                <ul className="pl-5">
                  <li>a.	osoba, której dane dotyczą, kwestionuje prawidłowość danych osobowych – na okres pozwalający Administratorowi sprawdzić prawidłowość tych danych; </li>
                  <li>b.	przetwarzanie jest niezgodne z prawem, a osoba, której dane dotyczą, sprzeciwia się usunięciu danych osobowych, żądając w zamian ograniczenia ich wykorzystywania;</li>
                  <li>c.	Administrator nie potrzebuje już danych osobowych do celów przetwarzania, ale są one potrzebne osobie, której dane dotyczą, do ustalenia, dochodzenia lub obrony roszczeń; </li>
                  <li>d.	osoba, której dane dotyczą, wniosła sprzeciw na mocy art. 21 ust. 1 (w związku z przetwarzaniem danych na podstawie zadania realizowanego w interesie publicznym lub w ramach sprawowania władzy publicznej przez Administratora) wobec przetwarzania – do czasu stwierdzenia, czy prawnie uzasadnione podstawy po stronie Administratora są nadrzędne wobec podstaw sprzeciwu osoby, której dane dotyczą.</li>
                </ul>
              </li>
              <li>e)	przenoszenia danych, jeżeli:
                <ul className="pl-5">
                  <li>a.	przetwarzanie odbywa się na podstawie udzielonej zgody lub na podstawie umowy, oraz</li>
                  <li>b.	przetwarzanie odbywa się w sposób zautomatyzowany. </li>
                </ul>
              </li>
              <li>f)	gdy przetwarzanie odbywa się na podstawie wyrażonej zgody - prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania;</li>
            </ul>
          </p>
          <p className="mt-5">Przysługuje Pani/Panu także prawo wniesienia sprzeciwu z przyczyn związanych ze szczególną Pani/Pana sytuacją wobec przetwarzania danych osobowych w zakresie, w jakim podstawą przetwarzania danych jest prawnie uzasadniony interes Administratorów, w tym do profilowania danych. W dowolnym momencie może Pani/Pan złożyć sprzeciw wobec przetwarzania danych osobowych w celach marketingowych.  </p>
          <p className="mt-5">W celu realizacji wymienionych uprawnień prosimy o kontakt z Administratorem listowanie, na adres siedziby lub za pośrednictwem Inspektora Ochrony Danych, do którego kontakt został podany powyżej.</p>
          <p className="mt-5">Jeśli uzna Pani/Pan, że przetwarzając Pani/Pana dane osobowe naruszono przepisy RODO lub inne przepisy prawa dotyczące ochrony danych osobowych, ma Pani/Pan prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</p>
          <p className="mt-5">Pani/Pana dane nie będą przekazywane poza Europejski Obszar Gospodarczy ani do organizacji międzynarodowych. Administratorzy nie będą podejmować decyzji, które opierają się wyłącznie na zautomatyzowanym przetwarzaniu, które wywoływałoby wobec Pani/Pana skutki prawne lub w podobny sposób znacząco na Panią/Pana wpływało.</p>
          <p className="mt-5">Więcej informacji o przetwarzaniu przez Administratorów danych osobowych znajduje się na stronie internetowej <a href="https://www.renault.pl/polityka-prywatnosci.html" rel="noreferrer" target="_blank" className="underline">https://www.renault.pl/polityka-prywatnosci.html</a>. </p>
        </div>
      </div>
  )
}

export default InformationFiding;