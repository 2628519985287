import React from 'react';
import Icon from './Icon';
import PropTypes from "prop-types";

const DownloadLink = ({ text, link }) => {
  return (
      <a href={link} target="_blank" rel="noreferrer" className="flex flex-col justify-center items-end md:max-w-292px pt-4 px-6 border border-solid border-grayBorder text-lg font-nouvelrbold">
        <span className="shrink-0 block w-full">{text}</span>
        <Icon id="download" viewBox="0 0 60 60" width="60" height="60" />
      </a>
  )
}

DownloadLink.defaultProps = {
  text: 'Pobierz',
  link: '/#'
}

DownloadLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
}

export default DownloadLink;