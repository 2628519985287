import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import Icon from './Icon';

const PopUp = ({ buttons, open, sendFnc, text, type, hideFnc }) => {
  const { t } = useTranslation();

  return (
      <div className={`${open ? 'block' : 'hidden'}`}>
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-popupOverlay"></div>
        <div className="fixed z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 lg:w-1/2 p-6 bg-white">
          <Icon id="x" viewBox="0 0 32 32" width="32" height="32" clsWrap="absolute top-1 right-1 hover:cursor-pointer" onClickFnc={hideFnc} />
          <p className={`text-base ${type === 'error' ? 'text-error' : 'text-green-700'}`}>{text}</p>
          {buttons &&
            <div className="flex justify-between mt-12 mb-6">
              <div className="relative px-4 py-15px font-nouvelrbold border border-black border-solid transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand pl-14 w-full" onClick={sendFnc} >
                <Icon id="check" viewBox="0 0 32 32" width="32" height="32" clsWrap="absolute top-1/2 left-4 transform -translate-y-1/2" />
                {t('popUpBtnConfirm')}
              </div>
              <div className="relative px-4 py-15px font-nouvelrbold border border-black border-solid transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand pl-14 w-full ml-8" onClick={hideFnc} >
                <Icon id="x" viewBox="0 0 32 32" width="32" height="32" clsWrap="absolute top-1/2 left-4 transform -translate-y-1/2" />
                {t('popUpBtnReject')}
              </div>
            </div>
          }
        </div>
      </div>
  )
}

PopUp.defaultProps = {
  buttons: false,
  hideFnc: '',
  open: false,
  text: '',
  type: 'error'
}

PopUp.propTypes = {
  buttons: PropTypes.bool,
  hideFnc: PropTypes.func,
  open: PropTypes.bool,
  sendFnc: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
}

export default PopUp;