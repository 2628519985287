import {components} from "react-select";
import Icon from "../components/_shared/Icon";
import React from "react";

export const defaultStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: 'auto',
    marginTop: '0.75rem',
    border: 'none',
    borderBottom: '1px solid #c1b9b2',
    borderRadius: 0,
    outline: 'none',
    boxShadow: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    outline: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: 0,
    color: '#978b7f',
    fontSize: '12px',
    lineHeight: '14px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'inherit',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#efdf00' : 'inherit',
    color: state.isSelected ? '#000' : '#000',
    '&:hover' : {
      backgroundColor: '#efdf00',
    }
  }),
};

export const DropdownIndicator = props => {
  return (
      <components.DropdownIndicator {...props}>
        <Icon id="upArr" viewBox="0 0 32 32" width="30" height="30" clsWrap="m-m5px rotate-180 fill-beige" />
      </components.DropdownIndicator>
  );
};