import React from 'react';
import { reverseDate } from "../../commons/functions";
import ProgressBarItem from "../_shared/ProgressBarItem";

const CompetitionsItem = ({ data, images }) => {
  return (
      <div className="md:flex mt-5 mb-10 md:my30px">
        <img src={`/images/${images[data.competitionId]}`} alt={data.competitionName} className="w-full md:w-auto mb-3 md:mb-0 md:mr-10" />
        <ProgressBarItem
          absence={data.currentEdition.targetMissing}
          dates={`${reverseDate(data.currentEdition.dateFrom)} - ${reverseDate(data.currentEdition.dateTo)}`}
          edition={data.currentEdition.editionName}
          goal={data.currentEdition.targetMissing}
          id={data.competitionId}
          lastImportDate={data.currentEdition.lastImportDate}
          percent={data.currentEdition.scorePercentage}
          percentageScore={data.currentEdition.percentageScore}
          prize={`${data.currentEdition.prize} zł`}
          result={data.currentEdition.score}
          targetType={data.currentEdition.targetType}
          title={data.competitionName}
        />
      </div>
  )
}

export default CompetitionsItem;