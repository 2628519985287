import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { pdfs } from '../../commons/functions';
import DownloadLink from "../_shared/DownloadLink";
import LoaderBig from '../_shared/LoaderBig';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionText = ({ competitionId, id }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : '';
  const [loaderBig, setLoaderBig] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    setLoaderBig(true);

    JwtInterceptor
        .get(`${API_URL}Competitions/edition/${id}/users`)
        .then((response) => {
          setUsers(response.data.serviceUsers);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        })
        .finally(() => {
          setLoaderBig(false);
        });
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (role === "ServiceChief" && id !== '') getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userHandler = (e, userId, action) => {
    e.preventDefault();

    const sendData = {
      "userId": userId,
      "excluded": action
    }

    JwtInterceptor
        .post(`${API_URL}Competitions/edition/${id}/user`, sendData)
        .then(() => {
          if (role === "ServiceChief" && id !== '') getUsers();
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        });
  }

  return (
      <div className="md:w-3/5 mb-11 md:mb-0">
        {role === "ServiceChief" && users.length > 0 &&
          <>
            {loaderBig ?
              <LoaderBig />
            :
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('participants')}</h3>
                <div className="overflow-x-auto md:overflow-x-hidden">
                  <table className="w-full">
                    <thead className="bg-black font-nouvelrbold text-white text-base">
                      <tr>
                        <th className="px-2 py-4 align-center text-left">{t('nameContactForm')}</th>
                        <th className="px-2 py-4 align-center text-left">{t('surname')}</th>
                        <th className="px-2 py-4 align-center text-left">{t('email')}</th>
                        <th className="px-2 py-4 align-center text-left">{t('phone')}</th>
                        <th className="px-2 py-4 align-center text-left max-w-20">{t('competitionTakes')}</th>
                        <th className="px-2 py-4 align-center text-left max-w-20">{t('addRemoveFromCompetition')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => {
                        return(
                          <tr key={user.id}>
                            <td className="px-2 py-4">{user.firstName}</td>
                            <td className="px-2 py-4">{user.lastName}</td>
                            <td className="px-2 py-4">{user.email}</td>
                            <td className="px-2 py-4">{user.phoneNumber}</td>
                            <td className="px-2 py-4">{user.participate ? t('yes') : t('no')}</td>
                            <td className="px-2 py-4 text-right">
                              <a href="/#" className="px-4 py-1 font-nouvelrbold border border-black border-solid transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand whitespace-nowrap" onClick={(e) => userHandler(e, user.id, user.participate)}>
                                {user.participate ? t('delete') : t('add')}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            }
          </>
        }

        <div className="font-nouvelrbold md:font-nouvelr text-sm md:text-16px">
          {competitionId === 'accessories' &&
            <>
              <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
              <div dangerouslySetInnerHTML={{__html: t(`competitionAccessoriesEtaps`) }} />
              <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
              <div dangerouslySetInnerHTML={{__html: t(`competitionAccessoriesMechanism`) }} />
              <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
              <div dangerouslySetInnerHTML={{__html: t(`competitionAccessoriesObjective`) }} />
            </>
          }
          {competitionId === 'battery' &&
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionBatteryEtaps`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionBatteryMechanism`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionBatteryObjective`) }} />
              </>
          }
          {competitionId === 'filters' &&
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionFiltersEtaps`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionFiltersMechanism`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionFiltersObjective`) }} />
              </>
          }
          {competitionId === 'videoRepair' &&
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionVideoRepairEtaps`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionVideoRepairMechanism`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionVideoRepairObjective`) }} />
              </>
          }
          {competitionId === 'wipers' &&
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionWipersEtaps`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionWipersMechanism`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionWipersObjective`) }} />
              </>
          }
          {competitionId === 'springPremiumPackages' &&
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionSpringPremiumPackagesEtaps`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionSpringPremiumPackagesMechanism`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionSpringPremiumPackagesObjective`) }} />
              </>
          }
          {competitionId === 'airClean' &&
              <>
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('etaps')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionAirCleanEtaps`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('rewardBonusSystem')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionAirCleanMechanism`) }} />
                <h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">{t('competitionSummarized')}</h3>
                <div dangerouslySetInnerHTML={{__html: t(`competitionAirCleanObjective`) }} />
              </>
          }
        </div>

        {/*<h3 className="mt-10 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">Mechanizm nagradzania</h3>*/}
        {/*<ul className="font-nouvelrbold md:font-nouvelr text-sm md:text-16px dot">*/}
        {/*  <li>7 zł nagrody za sprzedaż filtra antyalergicznego</li>*/}
        {/*  <li>Szef Serwisu otrzyma 10% nagrody osiągniętej przez Zespół Doradców Serwisowych (nie mniej niż 100 zł)</li>*/}
        {/*</ul>*/}

        {/*<h3 className="mt-20 mb-6 md:mb-8 font-nouvelrbold text-3xl md:text-40px">Cel sprzedażowy</h3>*/}
        {/*<p className="font-nouvelrbold md:font-nouvelr text-sm md:text-16px">liczba doradców serwisowych serwisu mechanicznego w BIR x 10 x miesiąc trwania Konkursu. Do konkursu nie są zaliczane filtry sprzedane w ramach pakietów EASY LIFE i Dacia SMART.</p>*/}
        {/*<p className="font-nouvelrbold md:font-nouvelr text-sm md:text-16px">Konkurs zostanie podsumowany w turach:</p>*/}
        {/*<ul className="mb-16 font-nouvelrbold md:font-nouvelr text-sm md:text-16px dot">*/}
        {/*  <li>za I turę Konkursu – do dn. 31.03.2023</li>*/}
        {/*  <li>za II turę Konkursu – do dn. 31.05.2023</li>*/}
        {/*  <li>za III turę Konkursu – do dn. 31.07.2023</li>*/}
        {/*  <li>za IV turę Konkursu – do dn. 30.09.2023</li>*/}
        {/*  <li>za V turę Konkursu – do dn. 30.11.2023</li>*/}
        {/*  <li>za VI turę Konkursu – do dn. 31.01.2024</li>*/}
        {/*</ul>*/}

        <DownloadLink text="Pobierz szczegółowy regulamin" link={pdfs[competitionId]} />
      </div>
  )
}

export default CompetitionText;