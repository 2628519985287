import React from 'react';
import Menu from '../Menu';
import CommunicationFrom from "./CommunicationFrom";

const Communication = () => {
  return (
      <>
        <Menu />
        <CommunicationFrom />
      </>
  );
}

export default Communication;